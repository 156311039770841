import firebase from 'firebase/app'
import 'firebase/auth'

// The configuration below is not sensitive data. You can serenely add your config here
const config = {
  apiKey: 'AIzaSyBi1l7PIsZcVtOuiLjhWW_fPKgY63KZ_eY',
  authDomain: 'velotheek.be',
  databaseURL: 'https://velotheek.firebaseio.com',
  projectId: 'velotheek',
  storageBucket: 'velotheek.appspot.com',
  messagingSenderId: '874898692738',
  appId: '1:874898692738:web:a7e150c9862eac62'
}
firebase.initializeApp(config)  

// TESTING
// import 'firebase/functions';
// firebase.functions().useFunctionsEmulator('http://localhost:5001')