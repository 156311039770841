export default {
  users: null,
  userRoles: [
    {
      name: ['Gebruiker', 'Utilisateur', 'User'],
      value: 0
    },
    {
      name: ['Fixer', 'Reparateur', 'Fixer'],
      value: 50
    },
    {
      name: ['Admin', 'Admin', 'Admin'],
      value: 99
    }
  ],
  defaultUser: {
    firstName: {
      // TODO: fetch these labels from the i18n state
      label: ['Voornaam', 'Prénom', 'name'],
      i18n: false,
      value: '',
      type: 'string',
      order: 0
    },
    lastName: {
      label: ['Achternaam', 'Nom', 'name'],
      i18n: false,
      value: '',
      type: 'string',
      order: 1
    },
    email: {
      label: ['Email', 'email', 'email'],
      i18n: false,
      value: '',
      type: 'string',
      order: 2
    },
    lang: {
      label: ['Taal', 'langue', 'language'],
      i18n: false,
      type: 'select',
      value: 0,
      order: 3
    },
    phone: {
      label: ['Telefoonnummer', 'Numéro de téléphone', 'Phone number'],
      i18n: false,
      type: 'string',
      value: '',
      order: 4
    },
    school: {
      label: ['School', 'École', 'School'],
      i18n: false,
      type: 'string',
      value: '',
      order: 5
    },
    organisations: []
  },
  selectedUser: {},
  selectedUserOrgToEdit: {},
  userError: null,
  userSuccess: null,
}
