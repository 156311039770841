export default {
  /**
   * Set the organisations
   */
  setOrganisations: (state, organisations) =>
    (state.organisations = organisations),
  setOrgFunction: (state, value) => (state.orgFunction = value),
  /**
   * Organisation input values
   */
  setOrganisationToCreate: (
    state,
    { value: value, fieldName: fieldName, lang: lang }
  ) => (state.orgToCreate[fieldName].value[lang] = value),
  setMailForOrganisation: (
    state,
    { value, fieldName, lang, mailId, partId }
  ) => {
    if (lang === null) {
      state.orgToCreate.mails[mailId][fieldName].value[lang] = value
    } else {
      if (partId === null) {
        state.orgToCreate.mails[mailId][fieldName].value[lang] = value
      } else {
        state.orgToCreate.mails[mailId].parts[partId].value[lang] = value
      }
    }
  },
  addOrg: (state, organisation) => state.organisations.push(organisation),
  /**
   * Reset organisation to create after adding to the database
   */
  resetOrganisationToCreate: state => {
    state.orgToCreate = {
      name: {
        label: ['naam', 'nom', 'name'],
        i18n: true,
        value: ['']
      },
      mails: [
        {
          type: 'bookingCreated',
          name: ['Booking aangemaakt', '', ''],
          descr: [
            'Wanneer iemand een boeking aanmaakt, wordt deze mail verstuurd',
            '',
            ''
          ],
          order: [0, 1, 2],
          subject: {
            label: ['Onderwerp', 'subject', 'subject'],
            i18n: true,
            value: ['']
          },
          parts: [
            {
              label: ['Intro NL', 'intro FR', 'intro'],
              i18n: true,
              value: ['']
            },
            {
              label: ['Outro NL', 'Outro FR', 'Outro'],
              i18n: true,
              value: ['']
            }
          ]
        }
      ]
    }
  },
  /**
   * Organisation input values
   */
  setOrgToEdit: (state, value) => (state.orgToCreate = value),
  setActiveOrganisation: (state, value) => (state.activeOrganisation = value),
  setLocations: (state, value) => (state.orgLocations = value),
  setLocationToCreateToDefaults: (state, value) =>
    (state.locationToCreate = JSON.parse(
      JSON.stringify(state.locationDefaults)
    )),
  setLocationToCreate: (
    state,
    { value: value, fieldName: fieldName, lang: lang }
  ) => {
    if (lang === null) {
      state.locationToCreate[fieldName].value = value
    } else state.locationToCreate[fieldName].value[lang] = value
  },
  setLocationToEdit: (state, value) => (state.locationToCreate = value),
  setLocationActiveInSearch: (state, { locationKey: locationKey, value: value}) => state.orgLocations[locationKey].activeInSearch = value,
  setSelectedLocation: (state, value) => (state.selectedLocation = value)
}