import state from './admin-users.state'
import mutations from './admin-users.mutations'
import actions from './admin-users.actions'
import getters from './admin-users.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
