export default {
  // TODO: Move to Firebase
  feedback: []
  // languages: [
  //   { locale: 'nl', id: 0, name: 'Nederlands', active: true },
  //   { locale: 'fr', id: 1, name: 'Français', active: false }
  // ],
  // adminCopy: {
  //   addProductNoSpecs: {
  //     ref: 'addProductNoSpecs',
  //     value: [
  //       'Er zijn nog geen specs toegevoegd',
  //       'Il y a pas des specs',
  //       'No specs yet'
  //     ],
  //     desc: ['Verschijnt wanneer er nog geen specs zijn toegevoegd', '', ''],
  //     context: ['Admin - voeg product toe', '', '']
  //   },
  //   addSpecNoSave: {
  //     ref: 'addSpecNoSave',
  //     value: ['Voeg een spec toe'],
  //     desc: ['Wanneer je wil starten met een spec toe te voegen', '', ''],
  //     context: ['Admin - voeg product toe', '', '']
  //   },
  //   addSpec: {
  //     ref: 'addSpec',
  //     value: ['Voeg spec toe'],
  //     desc: ['Wanneer je een spec aan het toevoegen bent', '', ''],
  //     context: ['Admin - voeg product toe > voeg spec toe', '', '']
  //   },
  //   specLabel: {
  //     ref: 'specLabel',
  //     value: ['Naam specificatie'],
  //     desc: ['', '', ''],
  //     context: ['Admin - voeg product toe > voeg spec toe', '', '']
  //   },
  //   specValue: {
  //     ref: 'specValue',
  //     value: ['Waarde specificatie'],
  //     desc: ['', '', ''],
  //     context: ['Admin - voeg product toe > voeg spec toe', '', '']
  //   },
  //   cancel: {
  //     ref: 'cancel',
  //     value: ['Annuleer', 'stop', 'stop'],
  //     desc: ['', '', ''],
  //     context: ['Main', '', '']
  //   },
  //   add: {
  //     ref: 'add',
  //     value: ['Voeg toe', 'stop', 'stop'],
  //     desc: ['', '', ''],
  //     context: ['Main', '', '']
  //   },
  //   addProduct: {
  //     ref: 'addProduct',
  //     value: ['Voeg product toe', '', ''],
  //     desc: ['', '', ''],
  //     context: ['Main', '', '']
  //   },
  //   saveProduct: {
  //     ref: 'saveProduct',
  //     value: ['Sla wijzigingen op', '', ''],
  //     desc: ['', '', ''],
  //     context: ['Main', '', '']
  //   },
  //   label: {
  //     ref: 'label',
  //     value: ['Label', '', ''],
  //     desc: ['', '', ''],
  //     context: ['Main', '', '']
  //   },
  //   value: {
  //     ref: 'value',
  //     value: ['Waarde', '', ''],
  //     desc: ['', '', ''],
  //     context: ['Main', '', '']
  //   },
  //   remove: {
  //     ref: 'remove',
  //     value: ['Verwijder', '', ''],
  //     desc: ['', '', ''],
  //     context: ['Main', '', '']
  //   },
  //   addStock: {
  //     ref: 'addStock',
  //     value: ['Voeg stock toe', '', ''],
  //     desc: ['', '', ''],
  //     context: ['Admin - voeg product toe > voeg stock toe', '', '']
  //   },
  //   noStock: {
  //     ref: 'noStock',
  //     value: ['Er is geen stock voor dit product', '', ''],
  //     desc: ['', '', ''],
  //     context: ['Admin - voeg product toe > geen stock', '', '']
  //   },
  //   numberOfStockToAdd: {
  //     ref: 'numberOfStockToAdd',
  //     value: [
  //       "Schrijf de id's van de stock uit, telkens met een comma tussen elke id.",
  //       '',
  //       ''
  //     ],
  //     desc: ['', '', ''],
  //     context: ['Admin - voeg product toe > stock toevoegen', '', '']
  //   },
  //   navProducts: {
  //     ref: 'navProducts',
  //     value: ['Producten', '', ''],
  //     desc: ['', '', ''],
  //     context: ['Admin - Navigatie', '', '']
  //   },
  //   navHome: {
  //     ref: 'navHome',
  //     value: ['Dashboard', '', ''],
  //     desc: ['', '', ''],
  //     context: ['Admin - Navigatie', '', '']
  //   },
  //   organisation: {
  //     ref: 'organisation',
  //     value: ['Organisatie', '', ''],
  //     desc: ['', '', ''],
  //     context: ['Admin - Navigatie', '', '']
  //   },
  //   organisations: {
  //     ref: 'organisations',
  //     value: ['Organisaties', '', ''],
  //     desc: ['', '', ''],
  //     context: ['Admin - Navigatie', '', '']
  //   },
  //   navOrganisations: {
  //     ref: 'navOrganisations',
  //     value: ['Organisaties', '', ''],
  //     desc: ['', '', ''],
  //     context: ['Admin - Navigatie', '', '']
  //   },
  //   addOrganisation: {
  //     ref: 'addOrganisation',
  //     value: ['Voeg organisatie toe', '', ''],
  //     desc: ['', '', ''],
  //     context: ['Admin - Organisations > toevoegen', '', '']
  //   },
  //   saveOrganisation: {
  //     ref: 'saveOrganisation',
  //     value: ['Sla organisatie op', '', ''],
  //     desc: ['', '', ''],
  //     context: ['Admin - Organisations > aanpassen', '', '']
  //   },
  //   noOrganisations: {
  //     ref: 'noOrganisations',
  //     value: ['Er zijn nog geen organisaties toegevoegd', '', ''],
  //     desc: ['', '', ''],
  //     context: ['Admin - organisaties', '', '']
  //   },
  //   navUsers: {
  //     ref: 'navUsers',
  //     value: ['Gebruikers', '', ''],
  //     desc: ['', '', ''],
  //     context: ['Admin - Navigatie', '', '']
  //   },
  //   addUser: {
  //     ref: 'addUser',
  //     value: ['Voeg gebruiker toe', '', ''],
  //     desc: ['', '', ''],
  //     context: ['Admin - Navigatie', '', '']
  //   },
  //   editUser: {
  //     ref: 'editUser',
  //     value: ['Wijzig gegegevens gebruiker', '', ''],
  //     desc: ['', '', ''],
  //     context: ['Admin - Navigatie', '', '']
  //   },
  //   deleteUser: {
  //     ref: 'deleteUser',
  //     value: ['Ben je zeker dat je deze gebruiker wil verwijderen?', '', ''],
  //     desc: ['', '', ''],
  //     context: ['Admin - Gebruikers', '', '']
  //   },
  //   users: {
  //     ref: 'users',
  //     value: ['Gebruikers', '', ''],
  //     desc: ['', '', ''],
  //     context: ['Admin - Gebruikers', '', '']
  //   },
  //   saveUser: {
  //     ref: 'saveUser',
  //     value: ['Sla gebruiker op', '', ''],
  //     desc: ['', '', ''],
  //     context: ['Admin - Navigatie', '', '']
  //   },
  //   noUsersInList: {
  //     ref: 'noUsersInList',
  //     value: ['Er zijn nog geen gebruikers', '', ''],
  //     desc: ['', '', ''],
  //     context: ['Admin - gebruikers', '', '']
  //   },
  //   loading: {
  //     ref: 'loading',
  //     value: ['Gegevens aan het ophalen', '', ''],
  //     desc: ['', '', ''],
  //     context: ['Admin - laden', '', '']
  //   },
  //   name: {
  //     ref: 'name',
  //     value: ['Naam', '', ''],
  //     desc: ['', '', ''],
  //     context: ['Admin - laden', '', '']
  //   },
  //   deleteSure: {
  //     ref: 'deleteSure',
  //     value: ['Ben je zeker dat je dit item wil verwijderen?', '', ''],
  //     desc: ['', '', ''],
  //     context: ['Admin - delete prompt', '', '']
  //   },
  //   deleteOrganisationFromUser: {
  //     ref: 'deleteOrganisationFromUser',
  //     value: [
  //       'Ben je zeker dat je deze gebruiker de toegang tot deze organisatie wil ontzeggen?',
  //       '',
  //       ''
  //     ],
  //     desc: ['', '', ''],
  //     context: ['Admin - delete prompt', '', '']
  //   },
  //   addOrgLater: {
  //     ref: 'addOrgLater',
  //     value: [
  //       'Je kan later een organisatie toewijzen aan deze gebruiker',
  //       '',
  //       ''
  //     ],
  //     desc: ['', '', ''],
  //     context: ['Admin - delete prompt', '', '']
  //   },
  //   navBookings: {
  //     ref: 'navBookings',
  //     value: ['Reservaties', '', ''],
  //     desc: ['', '', ''],
  //     context: ['Admin - nav > bookings', '', '']
  //   },
  //   moreInfo: {
  //     ref: 'moreInfo',
  //     value: ['Meer info', '', ''],
  //     desc: ['', '', ''],
  //     context: ['Admin - nav > bookings', '', '']
  //   },
  //   language: {
  //     ref: 'language',
  //     value: ['Taal', '', ''],
  //     desc: ['', '', ''],
  //     context: ['Admin - add or edit user', '', '']
  //   },
  //   numberOfProducts: {
  //     ref: 'numberOfProducts',
  //     value: ['Aantal producten', '', ''],
  //     desc: ['', '', ''],
  //     context: ['Dashboard - booking list', '', '']
  //   },
  //   resendMail: {
  //     ref: 'resendMail',
  //     value: ['Verstuur reservatiemail opnieuw', '', ''],
  //     desc: ['', '', ''],
  //     context: ['Dashboard - booking in list', '', '']
  //   },
  //   addLocation: {
  //     ref: 'addLocation',
  //     value: ['Voeg een locatie toe', '', ''],
  //     desc: ['', '', ''],
  //     context: ['Admin - voeg of edit organisatie > voeg locatione toe', '', '']
  //   },
  //   noLocations: {
  //     ref: 'noLocations',
  //     value: ['Er is geen locatie voor deze organisatie', '', ''],
  //     desc: ['', '', ''],
  //     context: ['Admin - voeg of edit organisatie > voeg locatione toe', '', '']
  //   },
  //   viewMail: {
  //     ref: 'viewMail',
  //     value: ['Bekijk de uitleg', '', ''],
  //     desc: ['', '', ''],
  //     context: ['Admin - voeg of edit organisatie > voeg locatione toe', '', '']
  //   },
  //   role: {
  //     ref: 'role',
  //     value: ['Rol', '', ''],
  //     desc: ['', '', ''],
  //     context: ['Admin - voeg of edit gebruiker > stel rol in', '', '']
  //   },
  //   editOrganisation: {
  //     ref: 'editOrganisation',
  //     value: ['Pas organisatie aan', '', ''],
  //     desc: ['', '', ''],
  //     context: ['Admin - voeg of edit gebruiker > stel rol in', '', '']
  //   },
  //   startNewBooking: {
  //     ref: 'startNewBooking',
  //     value: ['Start nieuwe booking', 'Nouveau reservation', ''],
  //     desc: ['', '', ''],
  //     context: ['Dashboard - Knop om nieuwe booking te starten', '', '']
  //   },
  //   chooseOrganisation: {
  //     ref: 'chooseOrganisation',
  //     value: ['Kies de organisatie waarvoor je werkt', '', ''],
  //     desc: ['', '', ''],
  //     context: [
  //       'Dashboard - Knop om nieuwe booking te starten > Kies organisatie',
  //       '',
  //       ''
  //     ]
  //   },
  //   locations: {
  //     ref: 'locations',
  //     value: ['Locaties', '', ''],
  //     desc: ['', '', ''],
  //     context: ['Admin', '', '']
  //   },
  //   referenceOfStockItem: {
  //     ref: 'referenceOfStockItem',
  //     value: ['Id om product terug te vinden op de locatie', '', ''],
  //     desc: ['', '', ''],
  //     context: ['Admin - Voeg stock toe', '', '']
  //   },
  //   BookingDoneSendMail: {
  //     ref: 'BookingDoneSendMail',
  //     value: [
  //       'Je zal zo dadelijk een mail krijgen met een bevestiging. In deze mail krijg je uitleg hoe je aan de fietsen geraakt.',
  //       '',
  //       ''
  //     ],
  //     desc: ['', '', ''],
  //     context: ['Booking - Verstuurd, mail wordt verstuurd', '', '']
  //   },
  //   loginMailIsSend: {
  //     ref: 'loginMailIsSend',
  //     value: ['De mail om in te loggen is verzonden. Check je inbox!', '', ''],
  //     desc: ['', '', ''],
  //     context: ['Login - Mail om in te loggen is verstuurd', '', '']
  //   },
  //   sendLoginEmail: {
  //     ref: 'sendLoginEmail',
  //     value: ['Verstuur een login email naar dit emailadres.', '', ''],
  //     desc: ['', '', ''],
  //     context: ['Login - verstuur login email', '', '']
  //   },
  //   navTranslate: {
  //     ref: 'navTranslate',
  //     value: ['Vertalingen', '', ''],
  //     desc: ['', '', ''],
  //     context: ['Admin Nav - Copy in menu', '', '']
  //   }
  // }
}
