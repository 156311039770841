export default {
  organisations: null,
  orgFunction: null,
  locationToCreate: null,
  activeOrganisation: null,
  selectedLocation: null,
  locationDefaults: {
    activeInSearch: true,
    name: {
      label: ['Naam locatie', 'Nom de location', 'Name of location'],
      i18n: true,
      value: ['']
    },
    street: {
      label: ['Straat', 'Rue', 'Street'],
      i18n: true,
      value: ['']
    },
    number: {
      label: ['Nummer', 'Numero', 'Number'],
      i18n: false,
      value: ['']
    },
    zip: {
      label: ['Postcode', 'postcode', 'Postal Code'],
      i18n: false,
      value: ['']
    },
    city: {
      label: ['Stad', 'Ville', 'City'],
      i18n: true,
      value: ['']
    },
    info: {
      label: ['Extra informatie', 'extra Info', 'Extra information'],
      i18n: true,
      value: ['']
    }
  },
  orgLocations: null,
  orgToCreate: {
    /**
     * IF CHANGES HERE, ALSO CHANGE IN "mutation/resetOrganisationToCreate"
     * TODO: Make this flow better by adding a default that gets copied to orgToCreate
     */
    name: {
      label: ['naam', 'nom', 'name'],
      i18n: true,
      type: 'string',
      value: ['']
    },
    multipleBookings: {
      label: ['Meerdere boekingen op hetzelfde moment mogelijk', 'Plusieurs réservations possibles en même temps', ''],
      i18n: false,
      type: 'switch',
      value: false
    },
    mails: [
      {
        type: 'bookingCreated',
        name: ['Booking aangemaakt', '', ''],
        descr: [
          'Wanneer iemand een boeking aanmaakt, wordt deze mail verstuurd',
          '',
          ''
        ],
        order: [0, 1, 2],
        subject: {
          label: ['Onderwerp', 'subject', 'subject'],
          i18n: true,
          value: ['']
        },
        parts: [
          {
            label: ['Intro', 'intro', 'intro'],
            i18n: true,
            value: ['']
          },
          {
            label: ['Outro', 'Outro', 'Outro'],
            i18n: true,
            value: ['']
          }
        ]
      },
      {
        type: 'registerAccount',
        name: ['Stuur link om in te loggen', '', ''],
        descr: [
          'Wanneer een link naar de gebruiker wordt gestuurd om in te loggen.',
          '',
          ''
        ],
        order: [0, 1, 2],
        subject: {
          label: ['Onderwerp', 'subject', 'subject'],
          i18n: true,
          value: ['']
        },
        parts: [
          {
            label: ['Intro', 'intro', 'intro'],
            i18n: true,
            value: ['']
          },
          {
            label: ['Outro', 'Outro', 'Outro'],
            i18n: true,
            value: ['']
          }
        ]
      }
    ],
  }
  // itemDeletionPending: [],
  // itemCreationPending: false
}
