export default {
  products: null,
  stock: null,
  stockHeaders: [],
  stockToCreateHeaders: [
    { text: 'location', value: 'location', sortable: true },
    { text: 'reference', value: 'reference', sortable: true }
  ],
  productFunction: null,
  specDefaults: [
    {
      i18n: true,
      value: [],
      type: 'string',
      typeLabel: ['tekst', 'texte', 'text'],
      label: []
    },
    {
      i18n: false,
      value: 0,
      type: 'number',
      typeLabel: ['nummer', 'nombre', 'number'],
      label: []
    },
    {
      i18n: false,
      value: [0, 1],
      type: 'range',
      typeLabel: ['bereik', 'porteé', 'range'],
      label: []
    }
  ],
  productToCreate: {
    /**
     * IF CHANGES HERE, ALSO CHANGE IN "mutation/resetProductToCreate"
     * TODO: Make this flow better by adding a default that gets copied to productToCreate
     */
  
    default: {
      reserve: {
        label: ['Aantal fietsen als reserve', 'Vélos en réserve', 'Number of bikes as reserve'],
        i18n: false,
        value: 0,
        type: 'number',
        order: 0
      },
      name: {
        label: ['naam', 'nom', 'name'],
        i18n: true,
        value: [''],
        type: 'string',
        order: 1
      },
      descr: {
        label: ['Omschrijving', 'description', 'description'],
        i18n: true,
        value: [''],
        type: 'string',
        order: 2
      },
      org: {
        label: ['Organisatie', 'organisation', 'organisation'],
        i18n: true,
        value: null,
        type: 'button',
        order: 3
      },

      image: {
        label: ['Afbeelding', 'Image', 'Image'],
        i18n: false,
        value: null,
        type: 'file',
        order: 4
      }
    },
    specs: []
  },
  stockToCreate: {
    reference: '',
    location: ''
  },
  stockListToCreate: [],
  newSpec: null,
  productDeletionPending: [],
  productCreationPending: false,
  stockStatus: [
    {'description': ['Ok', 'ok'], 'id': 0, 'color': 'green'},
    {'description': ['Probleem', 'Probleme'], 'id': 1, 'color': 'red'}
  ],
}
