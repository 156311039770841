import state from './admin-organisations.state'
import mutations from './admin-organisations.mutations'
import actions from './admin-organisations.actions'
import getters from './admin-organisations.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
