import Vue from 'vue'
import Vuex from 'vuex'
import authentication from './authentication'
import app from './app'
import admin from './admin'
import adminProducts from './admin-products'
import adminOrganisations from './admin-organisations'
import adminUsers from './admin-users'
import translations from './translations'
import adminBookings from './admin-bookings'
import notifications from './notifications'

Vue.use(Vuex)
/* If you don't know about Vuex, please refer to https://vuex.vuejs.org/ */
export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    authentication,
    app,
    admin,
    adminProducts,
    adminOrganisations,
    adminUsers,
    translations,
    adminBookings,
    notifications
  }
})
