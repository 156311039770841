const _ = require('lodash')
export default {
  /**
   * CREATE PRODUCTS
   */
  /**
   * Product input values
   */
  setProductToCreate: (
    state,
    { value: value, fieldName: fieldName, lang: lang }
  ) => {
    const editProduct = state.productToCreate;
    if (lang === null) {
      editProduct['default'][fieldName].value = value
    } else {
      const editProduct = state.productToCreate;
      editProduct['default'][fieldName].value[lang] = value
    }
    state.productToCreate = _.cloneDeep(editProduct)
  },
  setProductSpecToCreate: (
    state,
    { value: value, fieldName: fieldName, label: label, lang: lang }
  ) => {
    const editProduct = state.productToCreate;
    if (lang === null) {
      if(label) {
        editProduct['specs'][fieldName].label = value
      } else {
        editProduct['specs'][fieldName].value = value
      }
    } else {
      if(label) {
        editProduct['specs'][fieldName].label[lang] = value
      } else {
        editProduct['specs'][fieldName].value[lang] = value
      }
    }
    state.productToCreate = _.cloneDeep(editProduct)
  },
  setProductToEdit: (state, value) => {
    value = _.merge(state.productToCreate, value);
    state.productToCreate = value
  },
  setProductFunction: (state, value) => (state.productFunction = value),
  /**
   * After clicking on a product, set the stock
   */
  setStock: (state, value) => (state.stock = value),
  /**
   * After setting the stock, create a header array for the vuetify data table (https://vuetifyjs.com/en/components/data-tables)
   */
  setStockHeader: (state, value) => {
    state.stockHeaders = value
  },
  /**
   * Remove a spec from the specs
   */
  removeSpec: (state, value) => state.productToCreate.specs.splice(value, 1),
  /**
   * Reset product to create after adding to the databas
   * TODO: work with a default in the state, so the reset is easier (and better)
   */
  resetProductToCreate: state => {
    state.productToCreate = {
      default: {
        reserve: {
          label: ['Aantal fietsen als reserve', 'Nombre de vélos en réserve', 'Nombre de vélos en réserve'],
          i18n: false,
          value: 0,
          type: 'number'
        },
        name: {
          label: ['naam', 'nom', 'name'],
          i18n: true,
          value: [''],
          type: 'string'
        },
        descr: {
          label: ['Omschrijving', 'description', 'description'],
          i18n: true,
          value: [''],
          type: 'string'
        },
        org: {
          label: ['Organisatie', 'organisation', 'organisation'],
          i18n: true,
          value: null,
          type: 'button'
        },

        image: {
          label: ['Afbeelding', 'Image', 'Image'],
          i18n: false,
          value: 0,
          type: 'file'
        }
      },
      specs: []
    }
  },
  /**
   * Reset the fields of the spec to base
   */
  resetNewSpec: state => (state.newSpec = null),
  /**
   * Set the fields of the spec the users wants to add
   */
  setNewSpecFields: (state, spec) => {
    // state.productNewSpec.i18n = spec.i18n
    // state.productNewSpec.value = spec.value
    // state.productNewSpec.type = spec.type
    // state.productNewSpec.label = spec.label
    state.newSpec = JSON.parse(JSON.stringify(spec))
  },
  /**
   * Set the spec to be added to the product
   */
  setNewSpecField: (
    state,
    { value: value, fieldName: fieldName, lang: lang }
  ) => {
    if (lang !== null) {
      state.newSpec[fieldName][lang] = value
    } else {
      state.newSpec[fieldName] = value
    }
  },
  /**
   * Add a new spec to the product
   */
  productAddSpec: (state, spec) => {
    state.productToCreate.specs.push(spec)
  },
  /**
   * Products
   */
  setProducts: (state, products) => (state.products = products),
  addProduct: (state, product) => state.products.push(product),
  removeProductById: (state, productId) => {
    const index = state.products.findIndex(product => product.id === productId)
    state.products.splice(index, 1)
  },
  /**
   * Products deletion
   */
  addProductDeletionPending: (state, productId) =>
    state.productDeletionPending.push(productId),
  removeProductDeletionPending: (state, productId) => {
    const index = state.products.findIndex(product => product.id === productId)
    state.productDeletionPending.splice(index, 1)
  },
  /**
   * Products creation
   */
  setProductCreationPending: (state, value) =>
    (state.productCreationPending = value),
  setStockToCreate: (state, { field: field, value: value }) =>
    (state.stockToCreate[field] = value),
  resetStockToCreate: state =>
    (state.stockToCreate = {
      reference: '',
      location: ''
    }),
  setStockListToCreate: (state, value) =>
    (state.stockListToCreate = JSON.parse(JSON.stringify(value))),
  setStockItemInListToCreate: (
    state,
    { field: field, value: value, id: id }
  ) => {
    state.stockListToCreate[id][field] = value
    // state.stockListToCreate = state.stockListToCreate;
  }
}
