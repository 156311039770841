import { cloneDeep, find } from 'lodash'

export default {
  resetAvailableStock: (state) => {
    state.availableStock = {}
  },
  setCalReady: (state, val) => {
    state.calReady = val
  },
  resetSelectedStock: (state) => {
    state.selectedStock = {}
    state.selectedStock = JSON.parse(JSON.stringify(state.selectedStock))
  },
  setSelectedStock: (state, selectedStock) => {
    state.selectedStock = JSON.parse(JSON.stringify(selectedStock))
  },
  setAllProducts: (state, products) => {
    state.allProducts = products
  },
  setUserActiveBookings: (state, value) => {
    state.userActiveBookings = value
  },
  setLoadingStock: (state, value) => (state.loadingStock = value),
  removeOneFromSelection: (state, { productId: productId, item: item }) => {
    state.selectedStock[productId].splice(
      state.selectedStock[productId].indexOf(item),
      1
    )
    state.selectedStock = JSON.parse(JSON.stringify(state.selectedStock))
  },
  addOneToSelection: (state, { productId: productId, item: item }) => {
    state.selectedStock[productId].push(item)
    state.selectedStock = JSON.parse(JSON.stringify(state.selectedStock))
  },
  addOneToAvailable: (state, { productId: productId, item: item }) => {
    state.availableStock[productId].push(item)
    state.availableStock = JSON.parse(JSON.stringify(state.availableStock))
  },
  removeOneFromAvailable: (state, { productId: productId, item: item }) => {
    state.availableStock[productId].splice(
      state.availableStock[productId].indexOf(item),
      1
    )
    state.availableStock = JSON.parse(JSON.stringify(state.availableStock))
  },
  setSendingBooking: (state, value) => (state.sendingBooking = value),
  setSelectionProducts: (state, { productId: productId }) => {
    state.selectedStock[productId] = []
    state.selectedStock = JSON.parse(JSON.stringify(state.selectedStock))
  },
  setSelectedStockPerProduct: (
    state,
    { productId: productId, selectedStock: selectedStock }
  ) => {
    state.selectedStock[productId] = [...selectedStock]
    state.selectedStock = JSON.parse(JSON.stringify(state.selectedStock))
  },
  setAvailableStock: (state, availableStock) => {
    state.availableStock = cloneDeep(availableStock)
  },
  setActiveBookings: (state, activeBookings) => {
    state.activeBookings = {}

    state.activeBookings = cloneDeep(activeBookings)

    if (state.activeBookings[0]) state.activeBookings[0].testje = 'tester'
  },
  resetBookingDateRange: (state) => {
    state.bookingDateRange = {
      start: null,
      end: null,
    }
  },
  setBookingDateRange: (state, bookingDateRange) => {
    state.bookingDateRange = {
      start: bookingDateRange.start,
      end: bookingDateRange.end,
    }
  },
  setSelectedOrgForBooking: (state, value) => {
    state.selectedOrgForBooking = value
  },
  setEditingBooking: (state, value) => {
    state.editingBooking = value
  },
  setFreeTimeBooking: (state, { time, value }) => {
    state.freeTimeBooking[time] = value
  },
  setTimeSlotBooking: (state, { slotId, value }) => {
    state.timeslots[slotId].value = value
  },

  setTimeSlotBookingAvailable: (state, { slotId, value }) => {
    state.timeslots[slotId].disabled = value
  },
  resetUnAvailableSlots: (state, slotId) => {
    state.vCalAttributes[slotId].dates = []
    state.vCalAttributes = JSON.parse(JSON.stringify(state.vCalAttributes))
  },
  setUnAvailableSlots: (state, { slotId, date }) => {
    state.vCalAttributes[slotId].dates.push(date)
    state.vCalAttributes = JSON.parse(JSON.stringify(state.vCalAttributes))
  },
  resetDisabledDate: (state, date) => {
    const vCalDisabledDates = [
      {
        start: null,
        end: new Date(),
      },
      {
        weekdays: [1, 7],
      },
    ]
    state.vCalDisabledDates = JSON.parse(JSON.stringify(vCalDisabledDates))
  },
  addDisabledDate: (state, date) => {
    state.vCalDisabledDates.push({ start: date, end: date })
    // console.log('added disabled date', date)
    state.vCalDisabledDates = JSON.parse(
      JSON.stringify(state.vCalDisabledDates)
    )
    console.log(state.vCalDisabledDates)
  },
  setSelectedTimeSlot: (state, value) => (state.selectedTimeSlot = value),
  setActiveBookingsList: (state, value) => (state.activeBookingsList = value),
  setDateAsSelected: (state, date) => {
    state.vCalAttributes[4].dates = [date]
    // state.vCalDisabledDates = JSON.parse(JSON.stringify(state.vCalDisabledDates))
  },
  resetDateAsSelected: (state) => {
    state.vCalAttributes[4].dates = []
    state.vCalDisabledDates = [
      {
        start: null,
        end: new Date(),
      },
      {
        weekdays: [1, 7],
      },
    ]
    state.vCalDisabledDates = JSON.parse(
      JSON.stringify(state.vCalDisabledDates)
    )
  },
  setDateMode: (state, val) => {
    state.dateMode = val
    // state.vCalDisabledDates = JSON.parse(JSON.stringify(state.vCalDisabledDates))
  },
  deleteBooking: (state, { bookingId, toDelete }) => {
    const booking = find(
      state.activeBookingsList,
      (booking) => booking.id === bookingId
    )

    booking.toDelete = toDelete
    // state.availableStock = {}
  },
}
