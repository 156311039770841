import { find } from 'lodash'
import Vue from 'vue'
import UsersDB from '@/firebase/users-db'
import UsersOrganisationDB from '@/firebase/users-organisations-db'
/**
 * Import Vue because of MomentJS, for changing the locale when the user gets loaded or changes its locale
 */

export default {
  /**
   * Fetch users
   */
  getUsers: async ({ rootState, commit }) => {
    const usersDB = new UsersDB(rootState.authentication.user.id)
    const users = await usersDB.readAll({})
    commit('setUsers', users)
  },
  /**
   * Start creating a new user
   */
  startCreatingNewUser: async ({ commit }) => {
    commit('resetSelectedUser')
    // commit('setUserFunction', 'create')
  },
  /**
   * Add a user in the FB
   */
  createUserFB: async (
    { state, dispatch, commit, rootState },
    { user: user }
  ) => {
    const usersDB = new UsersDB(rootState.authentication.user.id)
    const selectedUser = await usersDB.create(user)
    return selectedUser
  },
  /**
   * Get organisations of a user from Firebase
   */
  // getOrganisationsFB: async (
  //   { state, rootState, commit },
  //   { userId: userId }
  // ) => {
  //   const UsersOrganisationDb = new UsersOrganisationDB({
  //     userId: userId
  //   })
  //   const userOrganisations = await UsersOrganisationDb.readAll({})
  //   return userOrganisations
  // },
  /**
   * Get organisations of a user
   */
  // getOrganisations: (
  //   { state, rootState, commit, dispatch },
  //   { userId: userId }
  // ) => {
  //   dispatch('getOrganisationsFB', { userId: userId }).then(
  //     userOrganisations => {
  //       commit('setUserOrganisations', userOrganisations)
  //     }
  //   )
  // },
  /**
   * Set userOrg to edit
   */
  setUserOrgToEdit: ({ commit, dispatch }, { userOrg: userOrg }) => {
    commit('setSelectedUserOrgToEdit', userOrg)
  },
  /**
   * Save a user
   */
  updateUser: async ({ state, rootState, dispatch }) => {
    const usersDB = new UsersDB(rootState.authentication.user.id)
    const updatedUser = await usersDB.update(state.selectedUser)
    dispatch('getUsers')
  },
  /**
   * Add an organisation to a user in FB
   */
  // addOrganisationToUserFB: async (
  //   { rootState },
  //   { orgId: orgId, role: role, userId: userId }
  // ) => {
  //   // TODO: Check if users already exists, if so, don't add
  //   const data = { orgId: orgId, role: role, userId: userId }
  //   const UsersOrganisationDb = new UsersOrganisationDB({
  //     userId: rootState.authentication.user.id
  //   })
  //   const userOrganisations = await UsersOrganisationDb.create(data)
  // },
  // addOrganisationToUser: (
  //   { dispatch, rootState },
  //   { orgId: orgId, role: role, userId: userId }
  // ) => {
  //   dispatch('addOrganisationToUserFB', {
  //     orgId: orgId,
  //     role: role,
  //     userId: userId
  //   }).then(() => {
  //     dispatch('getOrganisations', { userId: rootState.authentication.user.id })
  //   })
  //   dispatch(
  //     'adminOrganisations/addUserToOrganisation',
  //     {
  //       orgId: orgId,
  //       role: role,
  //       userId: userId
  //     },
  //     { root: true }
  //   ).then(() => {
  //     dispatch('getOrganisations', { userId: userId })
  //   })
  // },
  /**
   * Update the organisation of the user
   */
  // updateOrganisationToUser: async (
  //   { state, rootState, dispatch },
  //   { id: id, orgId: orgId, role: role, userId: userId }
  // ) => {
  //   const UsersOrganisationDb = new UsersOrganisationDB({
  //     userId: rootState.authentication.user.id
  //   })
  //   const data = { id: id, orgId: orgId, role: role, userId: userId }
  //   const userOrganisations = await UsersOrganisationDb.update(data)
  //   dispatch(
  //     'adminOrganisations/updateUserToOrganisation',
  //     { id: orgId, role: role, userId: userId },
  //     { root: true }
  //   ).then(() => {})
  //   dispatch('getOrganisations', { userId: rootState.authentication.user.id })
  // },
  // /**
  //  * Remove organisation from user
  //  */
  // removeOrganisationFromUserFB: async (
  //   { dispatch },
  //   { orgId: orgId, userId: userId }
  // ) => {
  //   const UsersOrganisationDb = new UsersOrganisationDB({
  //     userId: userId
  //   })
  //   const userOrganisations = await UsersOrganisationDb.delete(orgId)
  // },
  // removeOrganisationFromUser: (
  //   { dispatch },
  //   { orgId: orgId, userId: userId }
  // ) => {
  //   dispatch('removeOrganisationFromUserFB', {
  //     orgId: orgId,
  //     userId: userId
  //   }).then(value => {
  //     dispatch('getOrganisations', { userId: userId })
  //   })
  // },
  /**
   * Delete a user
   */
  deleteUserFB: async ({}, { userId: userId }) => {
    const UsersDb = new UsersDB()
    const users = await UsersDb.delete(userId)
    return users
  },
  deleteUser: ({ dispatch }, { userId: userId }) => {
    /**
     * TODO:
     * Before removing the user we have to remove him from the organisations
     * A) Get the organisations of the user
     * B) Delete the user from these organisations
     */
    dispatch('deleteUserFB', { userId: userId }).then(value => {
      dispatch('getUsers')
    })
  },
  addUser: ({ dispatch, commit }, { user: user }) => {
    dispatch('createUserFB', { user: user }).then(value => {
      // TODO: Figure out how to add organisations here
      commit('addUser', value)
      commit('resetSelectedUser')
      // commit('setUserFunction', null)
      dispatch('getUsers')
    })
  },
  setMomentLanguage: ({ rootState }, language) => {
    Vue.moment.locale(language)
  },
  /**
   * Set user language
   */
  setUserLanguage: async ({ commit, dispatch }, { user: user }) => {
    commit('setUserToEdit', user)
    // commit('setUserFunction', 'edit')
    dispatch('setMomentLanguage', user.lang.value.locale)
  },
  addUserOrganisations: ({ state, commit }, { orgId: orgId, role: role }) => {
    let organisations = {}
    if (state.selectedUser.organisations === undefined) {
      organisations[orgId] = role
    } else {
      organisations = { ...state.selectedUser.organisations, [orgId]: role }
    }
    commit('setUserOrganisations', organisations)
    commit('resetUserOrgToEdit')
    // TODO: also save this at the organisation level
  },
  removeUserOrganisation: ({ state, commit }, { orgId: orgId }) => {
    commit('removeUserOrganisation', orgId)
  }
}
