import firebase from 'firebase/app'
import { isNil } from 'lodash'

let asyncFirebaseFunctions = null
// Lazy load firestore with async import is important for performance
export default () => {
  if (isNil(asyncFirebaseFunctions)) {
    asyncFirebaseFunctions = import(/* webpackChunkName: "chunk-firestore" */ 'firebase/functions').then(
      () => {
        firebase.functions()
        return firebase.functions()
      }
    )
  }
  return asyncFirebaseFunctions
}
