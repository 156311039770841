<template>
  <div class="wrapper">
    <div >
    <v-dialog
      v-model="dialog"
      persistent
      width="500"
    >
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="red lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Click Me
        </v-btn>
      </template> -->

      <v-card>
        <v-card-title class="headline grey lighten-2">
          <copy item="newContentTitle" :lang="userLang"></copy>
        </v-card-title>

        <v-card-text>
          <br>
          <copy item="newContentText" :lang="userLang"></copy>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            
            text
            @click="$emit('refresh'); dialog = false"
          >
            <copy item="reload" :lang="userLang"></copy>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
<!--   
    <template v-if="refreshingApp">
      Loading new content...
    </template> -->
    <!-- <template v-else>
      New content available. Please
      <span class="refresh-btn" @click="$emit('refresh')">REFRESH</span>
    </template> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState('authentication', ['userLang'])
  },
  props: {
    refreshingApp: Boolean
  },
  data () {
      return {
        dialog: true
      }
    },
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 10px 20px;
  background-color: #333;
  color: white;
  display: inline-block;
  border-radius: 4px;

  .refresh-btn {
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
