// import someKindofDB from '@/firebase/someKindOff-db'

export default {
  setNotification: ({ commit, dispatch, rootGetters }, {type: type, msg: msg, action: action, lang}) => {
    
    if(lang !== null) msg = rootGetters['translations/copy'](msg, lang);
    console.log(msg)
    commit('setMessage', msg)
    commit('setType', type)
    commit('setAction', action)
    commit('setActive', true)
    setTimeout(function(){ dispatch('resetNotification')}, 6000);
  },
  resetNotification: ({ commit }) => {
    commit('setActive', false)
    commit('setMessage', null)
    commit('setType', null)
    commit('setAction', null)
  },
  loading: ({ commit }, value) => {
    commit('setLoading', value)
  },
}
