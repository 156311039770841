const _ = require('lodash')
export default {
  /**
   * Users
   */
  setUsers: (state, users) => (state.users = users),
  // setUserFunction: (state, value) => (state.userFunction = value),
  /**
   * Start using a basic user to populate further, for editing or creating of a user
   */
  resetSelectedUser: state =>
    (state.selectedUser = JSON.parse(JSON.stringify(state.defaultUser))),
  /**
   * Users input values
   */
  setSelectedUser: (state, { value: value, fieldName: fieldName }) => {
    state.selectedUser[fieldName]['value'] = value
  },
  setSelectedUserOrganisations: (state, { organisations: organisations }) => {
    state.selectedUser.organisations = JSON.parse(JSON.stringify(organisations))
    state.selectedUser = JSON.parse(JSON.stringify(organisations))
  },
  addUser: (state, user) => state.users.push(user),
  /**
   * User input values
   */
  setUserToEdit: (state, value) => {
    let user = _.merge(state.defaultUser, value);
    user.organisations = value.organisations;
    state.selectedUser = user;
  },
  setSelectedUserOrgToEdit: (state, value) =>
    (state.selectedUserOrgToEdit = JSON.parse(JSON.stringify(value))),
  editSelectedUserOrgToEdit: (
    state,
    { value: value, fieldName: fieldName }
  ) => {
    state.selectedUserOrgToEdit[fieldName] = value
  },
  resetUserOrgToEdit: state => (state.selectedUserOrgToEdit = {}),
  setUserOrganisations: (state, organisations) => {
    state.selectedUser.organisations = organisations
    state.selectedUser = JSON.parse(JSON.stringify(state.selectedUser))
  },

  removeUserOrganisation: (state, organisation) => {
    delete state.selectedUser.organisations[organisation]
    state.selectedUser = JSON.parse(JSON.stringify(state.selectedUser))
  },
  editUserOrganisations: (
    state,
    { organisation: organisation, role: role }
  ) => {
    state.selectedUser.organisations[organisation] = role
    state.selectedUser = JSON.parse(JSON.stringify(state.selectedUser))
  },
  setUserSuccess: (state, value) => {
    state.userSuccess = value;
  },
  setUserError: (state, value) => {
    state.userError = value;
  },
}
