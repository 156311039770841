import OrganisationsDB from '@/firebase/organisations-db'
import OrganisationsUserDB from '@/firebase/organisations-user-db'
import LocationsDB from '@/firebase/locations-db'

export default {
  /**
   * Fetch Organisation
   */
  getOrganisations: async ({ state, rootState, commit }) => {
    const organisationsDB = new OrganisationsDB(
      rootState.authentication.user.id
    )

    const organisations = await organisationsDB.readAll({})

    commit('setOrganisations', organisations)
  },
  /**
   * Get specific org
   */
  getOrganisationFB: async (
    { rootState, commit },
    { organisationId: organisationId }
  ) => {
    let userId
    if (rootState.authentication.user !== null) {
      userId = rootState.authentication.user.id
    } else {
      userId = null
    }
    const organisationsDB = new OrganisationsDB(userId)

    const organisation = await organisationsDB.read(organisationId)
    return organisation
  },
  /**
   * Start creating a new organisation
   */
  startCreatingNewOrganisation: async ({ commit }) => {
    commit('resetOrganisationToCreate')
    commit('setOrgFunction', 'create')
    commit('setLocations', null)
  },
  /**
   * Add an organisation
   */
  addOrg: async ({ state, commit, rootState }) => {
    const organisationsDB = new OrganisationsDB(
      rootState.authentication.user.id
    )
    // TODO: Add pending functions, for a better UX
    // commit('setOrganisationCreationPending', true)
    const createdOrg = await organisationsDB.create(state.orgToCreate)
    commit('addOrg', createdOrg)

    // commit('setOrganisationCreationPending', false)
    commit('resetOrganisationToCreate', false)
    commit('setOrgFunction', null)
  },
  /**
   * Set organisation to edit
   */
  setOrgToEdit: async ({ commit, dispatch }, { org: org }) => {
    commit('setOrgToEdit', org)
    commit('setOrgFunction', 'edit')
    dispatch('getLocations', { organisationId: org.id })
  },
  /**
   * Save an organisation
   */
  saveOrg: async ({ state, commit, rootState, dispatch }) => {
    const organisationsDB = new OrganisationsDB(
      rootState.authentication.user.id
    )
    // commit('setProductCreationPending', true)
    const saveOrg = await organisationsDB.update(state.orgToCreate)
    dispatch('getOrganisations')
    // commit('setProductCreationPending', false)
  },
  /**
   * Add a user to an organisation
   */
  addUserToOrganisation: async (
    { state, rootState },
    { orgId: orgId, role: role, userId: userId }
  ) => {
    // TODO: Check if users already exists, if so, don't add
    const organisationsUserDb = new OrganisationsUserDB({
      orgId: orgId,
      role: role,
      userId: userId,
    })
    const organisationUsers = await organisationsUserDb.create({
      orgId: orgId,
      role: role,
      userId: userId,
    })
  },
  /**
   * update a user from an organisation
   */
  updateUserToOrganisation: async (
    { state, rootState },
    { selectedUserOrgToEdit: selectedUserOrgToEdit }
  ) => {
    // TODO: Check if users already exists, if so, don't add
    const organisationsUserDb = new OrganisationsUserDB({
      userId: rootState.authentication.user.id,
    })
    const organisationUsers = await organisationsUserDb.update(
      selectedUserOrgToEdit
    )
  },
  /**
   * Remove a user from an organisation
   */
  removeUserFromOrganisation: async (
    { state },
    { orgId: orgId, userId: userId }
  ) => {
    const organisationsUserDb = new OrganisationsUserDB({ orgId: orgId })
    const organisationUsers = await organisationsUserDb.delete(userId)
  },
  /**
   * create location for an organisation
   */
  createLocation: async ({ state, commit, rootState, dispatch }) => {
    const locationsDb = new LocationsDB({
      userId: rootState.authentication.user.id,
      organisationId: state.orgToCreate.id,
    })
    // create stock, based on the number of stock
    locationsDb.create(state.locationToCreate)
    dispatch('getLocations', { organisationId: state.orgToCreate.id })
  },
  getLocations: async (
    { state, commit, rootState, dispatch },
    { organisationId: organisationId }
  ) => {
    const locationsDB = new LocationsDB({ organisationId: organisationId })
    const locations = await locationsDB.readAll({})
    commit('setLocations', locations)
  },
  getLocation: async (
    { state, commit, rootState, dispatch },
    { organisationId: organisationId, locationId: locationId }
  ) => {
    const locationsDB = new LocationsDB({ organisationId: organisationId })
    const location = await locationsDB.read(locationId)
    return location
  },

  updateLocation: async ({ state, commit, rootState, dispatch }) => {
    const locationsDB = new LocationsDB({
      organisationId: state.orgToCreate.id,
    })
    const locationToUpdate = await locationsDB.update(state.locationToCreate)
    dispatch('getLocations', {
      organisationId: state.orgToCreate.id,
    })
  },

  deleteLocation: async (
    { state, commit, rootState, dispatch },
    { locationId: locationId }
  ) => {
    const locationsDB = new LocationsDB({
      organisationId: state.orgToCreate.id,
    })
    const locationDeleted = await locationsDB.delete(locationId)
    dispatch('getLocations', {
      organisationId: state.orgToCreate.id,
    })
  },

  setLocationForProductSearch: (
    { rootState, state, commit, dispatch },
    { value: value }
  ) => {
    dispatch('adminBookings/resetBooking', null, { root: true })
    /* Set the  value for the radio to the correct value */
    for (let index = 0; index < state.orgLocations.length; index++) {
      const element = state.orgLocations[index]
      if (index === value) {
      }
    }
    commit('setSelectedLocation', value)

    // First we need to reset the selected date, if this is selected;
    commit('adminBookings/resetDateAsSelected', true, { root: true })
    commit('adminBookings/resetBookingDateRange', true, { root: true })
    /**
     * Because I switched from select to radio, I tweaked this,
     * but I like to keep the way it goes to the firestore function
     * this is why i just set all to false, and then set the one we selected to true
     */
    for (let index = 0; index < state.orgLocations.length; index++) {
      if (index === value) {
        commit('setLocationActiveInSearch', { locationKey: index, value: true })
      } else {
        commit('setLocationActiveInSearch', {
          locationKey: index,
          value: false,
        })
      }
    }

    /* We also now need to get the new available dates, as these are now split up per location */

    dispatch('adminBookings/getUnavailableDates', null, { root: true })

    /* If the daterange is set, redo the search for these locations */
    if (
      rootState.adminBookings.bookingDateRange.start !== null &&
      rootState.adminBookings.bookingDateRange.end !== null
    ) {
      commit('adminBookings/setLoadingStock', true, { root: true })
      dispatch(
        'adminBookings/getAvailableStockForBooking',
        {
          bookingStart: rootState.adminBookings.bookingDateRange.start,
          bookingEnd: rootState.adminBookings.bookingDateRange.end,
        },
        { root: true }
      ).then((availableStock) => {
        dispatch(
          'adminBookings/setStockForBookingModule',
          availableStock.data,
          { root: true }
        )
      })
    }
  },

  /**
   * TODO:
   * Create a function to remove organisations
   * Before removing the organisation we have to remove the organisation from all the users
   * & We need to remove all locations
   * A) Get the organisations of the user
   * B) Delete the user from these organisations
   * C) Get the locations of the organisation
   * D) remove locations
   * E) remove organisation
   */
}
