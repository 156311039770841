export default {
  setUser: (state, value) => {
    state.user = value
  },
  setUserLang: (state, value) => (state.userLang = value),
  setAdmin: (state, value) => (state.admin = value),
  setUserActiveOrganisation: (state, value) => {
    state.userActiveOrganisation = value
  }
}
