export default {
  availableStock: {},
  calReady: false,
  loadingStock: false,
  sendingBooking: false,
  selectedOrgForBooking: null,
  userActiveBookings: null,
  activeBookings: {},
  selectedStock: {},
  userBookings: {},
  allProducts: [],
  selectedTimeSlot: null,
  dateMode: 'null' /* null, multiple, range */,
  editingBooking: null,
  freeTimeBooking: { start: null, end: null },
  timeslots: [
    {
      name: ['Voormiddag', 'Matin'],
      value: false,
      disabled: false,
    },
    {
      name: ['Namiddag', 'Après midi'],
      value: false,
      disabled: false,
    },
    {
      name: ['Hele dag', 'Toute la journée'],
      value: false,
      disabled: false,
    },
  ],
  bookingDateRange: {
    start: null,
    end: null,
  },
  vCalDisabledDates: [
    {
      start: null,
      end: new Date(),
    },
    {
      weekdays: [1, 7],
    },
  ],
  vCalAttributes: [
    {
      highlight: {
        class: 'datePicked-before',
      },
      dates: [],
      // popover: {
      //   label: 'Reservatie enkel in de voormiddag mogelijk',
      //   visibility: 'hover',
      //   hideIndicator: true
      // }
    },
    {
      highlight: {
        class: 'datePicked-after',
      },
      dates: [],
      // popover: {
      //   label: 'Reservatie enkel in de namiddag mogelijk',
      //   visibility: 'hover',
      //   hideIndicator: true
      // },
    },
    {
      highlight: {
        class: 'velo-today',
      },
      dates: new Date(),
      // popover: {
      //   label: 'Reservatie enkel in de voormiddag mogelijk',
      //   visibility: 'hover',
      //   hideIndicator: true
      // }
    },
    {
      highlight: {
        class: 'velo-normalDay',
      },
      dates: [
        {
          start: new Date(),
          end: null,
          weekdays: [2, 3, 4, 5, 6],
        },
      ],
      // popover: {
      //   label: 'Reservatie enkel in de voormiddag mogelijk',
      //   visibility: 'hover',
      //   hideIndicator: true
      // }
    },
    {
      highlight: {
        class: 'velo-selected',
      },
      dates: [],
      // popover: {
      //   label: 'Reservatie enkel in de voormiddag mogelijk',
      //   visibility: 'hover',
      //   hideIndicator: true
      // }
    },
  ],
  activeBookingsList: [],
  bookingHeaders: [
    { text: 'Date', value: 'dateRange', sortable: true },
    { text: 'Moment', value: 'timeslots' },
    { text: 'User', value: 'userId' },
    { text: 'School', value: 'school' },
    { text: 'Location', value: 'locations' },
    { text: 'Total', value: 'totalProducts' },
    { text: 'Products', value: 'selectedStock' },
    { text: 'To Delete', value: 'toDelete' },
    { text: 'Actions', value: 'action', sortable: false, align: 'center' },
  ],
}

// headers: [
//   {
//     text: 'Dessert (100g serving)',
//     align: 'start',
//     sortable: false,
//     value: 'name',
//   },
//   { text: 'Calories', value: 'calories' },
//   { text: 'Fat (g)', value: 'fat' },
//   { text: 'Carbs (g)', value: 'carbs' },
//   { text: 'Protein (g)', value: 'protein' },
//   { text: 'Iron (%)', value: 'iron' },
// ],
