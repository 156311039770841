import feedbackDB from '@/firebase/feedback-db'

export default {
  getFeedback: async ({ rootState, commit }) => {
    const userId = rootState.authentication.user.id
    const constraints = null
    const feedbackDb = new feedbackDB(userId)
    const products = await feedbackDb.readAll({ constraints })
    return products
  }
}
