import { find, filter, flatten, cloneDeep, isEmpty, merge } from 'lodash'
import router from '@/router/'
/**
 * Import Vue because of MomentJS. When moving the code that uses moment to fb functions, remove this
 */
import Vue from 'vue'
import ActiveBookingsPerStockDB from '@/firebase/activeBookingsPerStock-db'
import ActiveBookingsDB from '@/firebase/activeBookings-db'
import StockFN from '@/firebase/stock-fn'

import firebase from 'firebase/app'

export default {
  getUnavailableDates: async ({ rootState, state, commit }) => {
    // for a v-if hack, because calendar isn't updating unavailable dates. Will set it back to true at the end.
    commit('setCalReady', false)

    // Reset the activeBookings
    commit('setActiveBookings', {})
    /* Remove the taken dates in the calender */
    commit('resetUnAvailableSlots', 0)
    commit('resetUnAvailableSlots', 1)
    commit('resetDisabledDate')

    const date = firebase.firestore.Timestamp.now()

    if (rootState.adminOrganisations.selectedLocation === null) return
    /* Get the activeInSearch Locations as an array, so we can filter the bookings based on locations */

    let activeLocations = []
    for (
      let index = 0;
      index < rootState.adminOrganisations.orgLocations.length;
      index++
    ) {
      const element = rootState.adminOrganisations.orgLocations[index]
      if (element.activeInSearch)
        activeLocations.push(
          rootState.adminOrganisations.orgLocations[index].id
        )
    }

    const constraints = [
      ['dateRange.start', '>', date],
      ['locations', 'array-contains-any', activeLocations],
    ]
    const orderBy = ['dateRange.start', 'asc']

    const activeBookingsDb = new ActiveBookingsDB({
      userId: rootState.authentication.user.id,
    })
    const activeBookings = await activeBookingsDb.readAll({
      constraints,
      orderBy,
    })

    commit('setActiveBookings', activeBookings)
    activeBookings.forEach((item, idx, array) => {
      /**
       * TODO: Optimize so we don't constantly go to the store on everu iteration
       * add them all to an array first or whatever, then commit as a whole
       */
      if (item.timeslots) {
        if (
          item.timeslots[0].value === true &&
          item.timeslots[1].value === true
        ) {
          commit(
            'addDisabledDate',
            Vue.moment(item.dateRange.start).format('YYYY, MM, DD')
          )
          commit('setUnAvailableSlots', {
            slotId: 0,
            date: Vue.moment(item.dateRange.start).add(1, 'days'),
          })
          commit('setUnAvailableSlots', {
            slotId: 1,
            date: Vue.moment(item.dateRange.start).add(1, 'days'),
          })
        }

        if (
          item.timeslots[0].value === true &&
          item.timeslots[1].value !== true
        ) {
          /**
           *  Check if this date has already been added to the other timeslot.
           *  If so, we need to make the date completely unavailable (not just the time slot)
           */

          // if(includes(vCalAttributes[1].dates, date)
          commit('setUnAvailableSlots', {
            slotId: 0,
            date: Vue.moment(item.dateRange.start).add(1, 'days'),
          })
        }
        if (
          item.timeslots[1].value === true &&
          item.timeslots[0].value !== true
        ) {
          // Only timeslot two is taken, add it to the correct timeslot
          commit('setUnAvailableSlots', {
            slotId: 1,
            date: Vue.moment(item.dateRange.start).add(1, 'days'),
          })
        }

        // I don't know what this does, think was related to time span
        // const intersection = state.vCalAttributes[1].dates.filter((element) =>
        //   state.vCalAttributes[0].dates.includes(element)
        // )
        // for (let i = 0; i < intersection.length; i++) {
        //   commit('addDisabledDate', intersection[i])
        // }
      }
    })
    commit('setCalReady', true)
  },

  setStockForBookingModule: ({ state, dispatch, commit }, availableStock) => {
    dispatch(
      'adminProducts/getProductsFB',
      { constraints: null },
      { root: true }
    ).then((Allproducts) => {
      // Set all products

      commit('setAllProducts', Allproducts)
      // Set an empty selection object for when the user selects stock
      for (let [key, value] of Object.entries(availableStock)) {
        commit('setSelectionProducts', { productId: key })
      }
      // Set the available stock
      commit('setAvailableStock', availableStock)
      commit('setLoadingStock', false)
    })
  },

  checkDateForBooking: ({ state, dispatch, commit }, { dateRange, type }) => {
    if (dateRange === null || dateRange === undefined) return
    if (type === 'range' && !dateRange.end) return

    commit('setDateAsSelected', dateRange)
    if (type === 'range' || type === 'single') commit('setLoadingStock', true)

    if (type === 'single' && dateRange.end === undefined) {
      dateRange = { start: dateRange, end: dateRange }
      commit('setBookingDateRange', dateRange)
      return
    }

    if (type === 'range' && dateRange.end)
      commit('setBookingDateRange', dateRange)

    /*  Set the timeslots
        1)  This only should be done if there is no daterange, so bookings only work on a single day basis
        2)  Find booking for this date: It can only be on: morning or afternoon.
            If both is true, the day is disabled. So once we found one, we can return.
            ->  We do this based on the vCalAttributes we already set.
                If the date is the same as the selected date, it's taken
        3)  Set the state.timeslots values (commit('setTimeSlotBooking', { slotId: slotId, value: value }))
    */

    // 1)

    if (type === 'single') {
      // Reset the timeslots
      commit('setTimeSlotBooking', { slotId: 0, value: false })
      commit('setTimeSlotBooking', { slotId: 1, value: false })
      commit('setTimeSlotBooking', { slotId: 2, value: false })

      commit('setTimeSlotBookingAvailable', { slotId: 0, value: false })
      commit('setTimeSlotBookingAvailable', { slotId: 1, value: false })
      commit('setTimeSlotBookingAvailable', { slotId: 2, value: false })

      let isSame = false

      /* Check if this date is already in the list with booked dates */

      for (var i = 0; i < 2; i++) {
        let timeSlot = state.vCalAttributes[i]
        // FIXME: I'm using this "subtract 1 day"-thing, because there is a timezone problem with dates i think / so it seems. Should use firebase timestamp
        if (
          timeSlot.dates.filter(
            (item) =>
              Vue.moment(item).subtract(1, 'days').format() ===
              Vue.moment(dateRange.start).format()
          ).length > 0
        )
          isSame = true
        if (isSame === true) {
          commit('setSelectedTimeSlot', i)
          commit('setTimeSlotBookingAvailable', { slotId: i, value: isSame })
          commit('setTimeSlotBookingAvailable', { slotId: 2, value: false })
          // if(i === 0) commit('setTimeSlotBookingAvailable', { slotId: 0, value: isSame })
          // if(i === 1) commit('setTimeSlotBookingAvailable', { slotId: 1, value: isSame })
          break
        }

        commit('setSelectedTimeSlot', null)
      }
    }

    commit('setSelectedTimeSlot', null)

    if (dateRange.end === undefined) return

    dispatch('getAvailableStockForBooking', {
      bookingStart: dateRange.start,
      bookingEnd: dateRange.end,
    }).then((availableStock) => {
      // COMEBACK

      if (type === 'range') {
        dispatch('setStockForBookingModule', availableStock.data)
        return
      }

      /**
       * TODO: If we have a booking on the same date, but only in a specific timeslot,
       * This still is in the whole "one day booking flow"
       * these actually become available again, so we have to add them to the available stock
       *  1) Get the booking for that date
       *  2) Add the stock to the availableStock
       */

      // 1)

      if (isEmpty(state.activeBookings)) {
        dispatch('setStockForBookingModule', availableStock.data)
        return
      }

      const activeBookingForThisDate = state.activeBookings.find(
        (item) =>
          Vue.moment(item.dateRange.start).format() ===
          Vue.moment(dateRange.start).format()
      )

      let combinedStock = {}
      // 2)

      if (activeBookingForThisDate !== undefined) {
        for (let key in availableStock.data) {
          if (availableStock.data.hasOwnProperty(key)) {
            if (activeBookingForThisDate.selectedStock[key] === undefined)
              activeBookingForThisDate.selectedStock[key] = []
            combinedStock[key] = [
              ...availableStock.data[key],
              ...activeBookingForThisDate.selectedStock[key],
            ]
          }
        }
        dispatch('setStockForBookingModule', combinedStock)
      } else {
        dispatch('setStockForBookingModule', availableStock.data)
      }

      return
    })
  },
  /**
   * Get all the bookings for this user from Firebase
   */
  getBookingsForUserFB: async ({ rootState, commit }, { userId: userId }) => {
    const activeBookingsDb = new ActiveBookingsDB({
      userId: userId,
    })
    const constraints = [
      ['userId', '==', userId],
      ['toDelete', '==', false],
    ]
    const orderBy = ['dateRange.start', 'asc']
    const activeBookings = await activeBookingsDb.readAll({
      constraints,
      orderBy,
    })
    return activeBookings
  },
  getAllBookingsForUser: ({ rootState, dispatch, commit }) => {
    dispatch('getBookingsForUserFB', {
      userId: rootState.authentication.user.id,
    }).then((activeBookings) => {
      commit('setUserActiveBookings', activeBookings)
    })
  },
  /**
   * Get a specific booking from FB
   */
  getBookingFB: async ({ rootState, commit }, { bookingId: bookingId }) => {
    const activeBookingsDb = new ActiveBookingsDB({
      userId: rootState.authentication.user.id,
    })
    const booking = await activeBookingsDb.read(bookingId)
    return booking
  },
  /**
   * Check if bookings are empty for a stock item
   */
  getBookingsPerStockItem: async (
    { rootState, commit },
    {
      userId: userId,
      productId: productId,
      stockId: stockId,
      constraints: constraints,
    }
  ) => {
    const activeBookingsPerStockDb = new ActiveBookingsPerStockDB({
      userId: userId,
      productId: productId,
      stockId: stockId,
    })
    const activeBookings = await activeBookingsPerStockDb.readAll({
      constraints,
    })
    activeBookings.forEach((item, idx, array) => {
      array[idx].productId = productId
      array[idx].stockId = stockId
    })
    return activeBookings
  },

  addOneToSelection: ({ state, commit }, { productId: productId }) => {
    const item = state.availableStock[productId][0]
    commit('addOneToSelection', { productId: productId, item: item })
    commit('removeOneFromAvailable', { productId: productId, item: item })
  },

  removeOneFromSelection: ({ state, commit }, { productId: productId }) => {
    const item = state.selectedStock[productId][0]
    commit('removeOneFromSelection', { productId: productId, item: item })
    commit('addOneToAvailable', { productId: productId, item: item })
  },
  /**
   * Check timing of booking compared to selection
   */
  checkTimingBooking: (
    { state, commit, rootState },
    {
      productId: productId,
      stockId: stockId,
      bookingStart: bookingStart,
      bookingEnd: bookingEnd,
      stockBookingStart: stockBookingStart,
      stockBookingEnd: stockBookingEnd,
    }
  ) => {
    const startIsBeforeEnd = Vue.moment(bookingStart).isSameOrBefore(
      stockBookingEnd
    )
    const startIsAfterStart = Vue.moment(bookingStart).isSameOrAfter(
      stockBookingStart
    )
    const endIsBeforeEnd = Vue.moment(bookingEnd).isSameOrBefore(
      stockBookingEnd
    )
    const endIsAfterStart = Vue.moment(bookingEnd).isSameOrAfter(
      stockBookingStart
    )
    if (
      (startIsAfterStart && startIsBeforeEnd) ||
      (endIsAfterStart && endIsBeforeEnd) ||
      (!startIsAfterStart && !endIsBeforeEnd)
    ) {
      return { productId: productId, stockId: stockId, value: false }
    }
    return { productId: productId, stockId: stockId, value: true }
  },

  getAvailableStockForBooking: async (
    { rootState, commit, dispatch },
    { bookingStart: bookingStart, bookingEnd: bookingEnd }
  ) => {
    // dispatch('resetBooking')
    const constraints = null

    const data = {
      locations: rootState.adminOrganisations.orgLocations,
      bookingStart: new Date(bookingStart).valueOf(),
      bookingEnd: new Date(bookingEnd).valueOf(),
    }

    const stockFn = new StockFN({
      fn: 'getAvailableStock',
    })

    const returnedStock = await stockFn.callFunction(data, constraints)

    return returnedStock
  },

  /**
   * Create a new booking on FB
   */
  createBookingFB: async ({ state, commit, rootState }, { data: data }) => {
    const activeBookingsDb = new ActiveBookingsDB({
      userId: data.userId,
    })

    const activeBooking = await activeBookingsDb.create(data)
    return activeBooking
  },

  /**
   * Update a booking on FB
   */
  updateBookingFB: async ({ state, commit, rootState }, { data: data }) => {
    const activeBookingsDb = new ActiveBookingsDB({
      userId: data.userId,
    })
    const activeBooking = await activeBookingsDb.update(data)
    return activeBooking
  },
  /**
   * Create a new booking
   */
  createBooking: (
    { state, commit, rootState, dispatch },
    {
      dateRange: dateRange,
      selectedStock: selectedStock,
      totalProducts: totalProducts,
      editing: editing,
    }
  ) => {
    /**
     * - Create an activeBooking in the activeBooktree
     * - Add a bookings Id at the level of the user?
     * - Loop through the selected products and add an active booking to these products
     *    - Start, end, productId, userId, bookingId
     */
    /**
     * Remove empty products
     */

    // TODO: add a spinning indication until booking is done
    commit('setSendingBooking', true)

    Object.keys(selectedStock).forEach(function (key) {
      if (selectedStock[key].length === 0) {
        delete selectedStock[key]
      }
    })

    /**
     * This is an extra when we shifted from multiple locations to 1 location:
     * We set an array with the locations that are involved in the booking
     * This is based on the adminOrgansations rootState.orgLocations[i].activeInSearch
     * */

    let activeLocations = []

    for (
      let index = 0;
      index < rootState.adminOrganisations.orgLocations.length;
      index++
    ) {
      const element = rootState.adminOrganisations.orgLocations[index]
      if (element.activeInSearch) activeLocations.push(element.id)
    }

    const data = {
      freeTimeBooking: state.freeTimeBooking,
      timeslots: state.timeslots,
      userId: rootState.authentication.user.id,
      dateRange: dateRange,
      selectedStock: selectedStock,
      totalProducts: totalProducts,
      status: 0,
      organisation: state.selectedOrgForBooking,
      locations: activeLocations,
      toDelete: false,
    }

    if (editing !== null) {
      data.id = editing
      dispatch('composeMail', {
        userId: data.userId,
        bookingId: data.id,
        mailId: 0,
      }).then((res) => {
        let reminderDate = new Date(data.dateRange.start)
        reminderDate.setDate(reminderDate.getDate() - 1)
        const composedMail = {
          ...res,
          send: false,
          reminder: false,
          after: false,
          reminderDate: reminderDate,
        }
        data.mail = composedMail
        dispatch('updateBookingFB', { data: data }).then((res) => {
          // TODO: DONE, send notif or something
        })
      })
    } else {
      dispatch('createBookingFB', { data: data }).then((res) => {
        const booking = res
        dispatch('composeMail', {
          userId: booking.userId,
          bookingId: booking.id,
          mailId: 0,
        }).then((mail) => {
          // Check if it is more interesting to send the mail from fb queue.
          const stockFn = new StockFN({
            fn: 'sendEmail',
          })
          const sendMail = stockFn.callFunction(mail)

          var reminderDate = new Date(booking.dateRange.start)
          reminderDate.setDate(reminderDate.getDate(reminderDate) - 1)
          const composedMail = {
            ...mail,
            send: false,
            reminder: false,
            after: false,
            reminderDate: reminderDate,
          }
          booking.mail = composedMail

          dispatch('updateBookingFB', { data: booking }).then((res) => {
            // TODO: DONE, send notif or something
          })
        })
        // dispatch('composeMail', {
        //   userId: data.userId,
        //   bookingId: res.id,
        //   mailId: 0
        // })
      })
    }
    dispatch('resetBooking')
    commit('resetBookingDateRange')
  },
  sendBookingEmail: (
    { rootState, state, commit, dispatch },
    { bookingId: bookingId }
  ) => {
    const userId = rootState.authentication.user.id
    const mailId = 0
    dispatch('composeMail', {
      userId: userId,
      bookingId: bookingId,
      mailId: mailId,
    }).then((mail) => {
      const stockFn = new StockFN({
        fn: 'sendEmail',
      })

      const sendMail = stockFn.callFunction(mail)
    })
  },
  createEmailPartProducts: async (
    { rootState, state, commit, dispatch },
    { products: products, stock: stock, locations: locations, lang: lang }
  ) => {
    return new Promise((resolve) => {
      /* TODO: This is a stupid hack, cos languages are switched here */
      // if(lang === 0) lang = 1
      // if(lang === 1) lang = 0
      /**
       * Loop through locations
       * For every location, print the bikes
       */

      let fullString = ''
      let count = 0
      for (const location in locations) {
        let locationString = ''
        const filteredStockItemsBasedOnLocation = filter(
          stock,
          (stockItem) => stockItem.location === locations[location].id
        )
        const theLocation = locations[location]

        if (lang === 0) {
          const adresString = `Locatie: Velotheek ${theLocation.name.value[0]}
Adres:
${theLocation.street.value[0]} ${theLocation.number.value}
${theLocation.zip.value} ${theLocation.city.value[0]}
Info:
${theLocation.info.value[1]}

Fietsen:
`
          locationString = `${locationString}
${adresString}`
          for (const i in filteredStockItemsBasedOnLocation) {
            const item = filteredStockItemsBasedOnLocation[i]
            const product = find(
              products,
              (product) => product.id === item.productId
            )
            locationString = `${locationString}
- ${product.default.name.value[0]}`
          }
          fullString = `${fullString} ${locationString}
-----------------------`
        } else {
          const adresString = `Lieu: Vélotheek ${theLocation.name.value[1]}

Adresse:
${theLocation.street.value[1]} ${theLocation.number.value}
${theLocation.zip.value} ${theLocation.city.value[1]}

Accès:
${theLocation.info.value[0]}

Vélos:

`
          locationString = `${locationString}
${adresString}`

          for (const i in filteredStockItemsBasedOnLocation) {
            const item = filteredStockItemsBasedOnLocation[i]
            const product = find(
              products,
              (product) => product.id === item.productId
            )
            locationString = `${locationString}
- ${product.default.name.value[lang]}`
          }
          fullString = `${fullString} ${locationString}
-----------------------`
        }
      }
      count++
      if (locations.length === count) resolve(fullString)
    })
  },

  composeMail: (
    { rootState, state, dispatch },
    { userId: userId, bookingId: bookingId, mailId: mailId }
  ) => {
    return new Promise((resolve) => {
      const theUserId = userId
      // TODO: Might need to get the user, instead of using authenticated user
      const user = rootState.authentication.user
      /**
       * USER =
       * email
       * firstName
       * id
       * lang
       * lastName
       */
      const lang = user.lang.value.id
      let organisationId
      let organisation
      let locations = []
      let locationsForMail
      let mail
      let booking
      let body
      let reorganizedStock = []
      let stock = []
      const products = []
      let partsProducts = null

      /**
       * get the booking based on the id
       */
      dispatch('getBookingFB', { bookingId: bookingId }).then((res) => {
        booking = res
        /**
         * BOOKING =
         * dateRange
         * id
         * selectedStock
         * status
         * totalProducts
         * userId
         * organisation
         */
        /**
         * let's put the stock in an array of objects, that makes it a bit easier to work with
         */
        Object.keys(booking.selectedStock).forEach((key, idx, array) => {
          booking.selectedStock[key].forEach((el) => {
            reorganizedStock = [
              ...reorganizedStock,
              { productId: key, stockId: el },
            ]
          })
        })
        const innerPromiseArray = [
          // Get the organisation Info
          dispatch(
            'adminOrganisations/getOrganisationFB',
            { organisationId: booking.organisation },
            { root: true }
          ),
        ]

        const promise4all = Promise.all(innerPromiseArray).then((result) => {
          organisation = result[0]
          mail = find(
            organisation.mails,
            (mail) => mail.type === 'bookingCreated'
          )
          const getAllStockArrayPromise = []
          const getAllProductArrayPromise = []
          reorganizedStock.forEach((el) => {
            getAllProductArrayPromise.push(
              dispatch(
                'adminProducts/getProductFB',
                { userId: userId, productId: el.productId },
                { root: true }
              )
            )
            getAllStockArrayPromise.push(
              dispatch(
                'adminProducts/getSpecficStockFB',
                {
                  userId: userId,
                  productId: el.productId,
                  stockId: el.stockId,
                },
                { root: true }
              )
            )
          })
          const promise4allProducts = Promise.all(
            getAllProductArrayPromise
          ).then((result) => {
            const theproducts = result
            const promise4allStock = Promise.all(getAllStockArrayPromise).then(
              (result) => {
                stock = result
                let getAllLocationsArrayPromise = []
                for (const stockItem of stock) {
                  if (!locations.includes(stockItem.location)) {
                    locations = [...locations, stockItem.location]
                    getAllLocationsArrayPromise = [
                      ...getAllLocationsArrayPromise,
                      dispatch(
                        'adminOrganisations/getLocation',
                        {
                          organisationId: booking.organisation,
                          locationId: stockItem.location,
                        },
                        { root: true }
                      ),
                    ]
                  }
                }
                const promise4allLocations = Promise.all(
                  getAllLocationsArrayPromise
                ).then((result) => {
                  const allLocations = result
                  dispatch('createEmailPartProducts', {
                    products: theproducts,
                    stock: stock,
                    locations: allLocations,
                    lang: lang,
                  }).then((result) => {
                    // Get moment

                    let moment = ''

                    if (
                      booking.timeslots[0].value &&
                      booking.timeslots[1].value
                    ) {
                      moment = booking.timeslots[2].name[lang]
                    } else {
                      if (booking.timeslots[0].value)
                        moment = booking.timeslots[0].name[lang]
                      if (booking.timeslots[1].value)
                        moment = booking.timeslots[1].name[lang]
                    }

                    partsProducts = result
                    // TODO: Translation of specific mail parts
                    let body = ''

                    if (lang === 0) {
                      body = `Hallo ${user.firstName.value},

${mail.parts[0].value[lang]}

Dit is je bestelling (referentie: ${bookingId})

Ophalen: ${Vue.moment(booking.dateRange.start).format(
                        'dddd, Do MMMM YYYY'
                      )}, ${moment} ${
                        booking.freeTimeBooking.start
                          ? 'om ' + booking.freeTimeBooking.start
                          : ''
                      }
${
  booking.dateRange.end
    ? 'Terugbrengen: ' +
      Vue.moment(booking.dateRange.end).format('dddd, Do MMMM YYYY')
    : ''
} ${booking.freeTimeBooking.end ? 'om ' + booking.freeTimeBooking.end : ''}

Producten (gegroepeerd per locatie):
${result}

${mail.parts[1].value[lang]}`
                    } else {
                      body = `Bonjour ${user.firstName.value},

${mail.parts[0].value[lang]}

Votre réservation (ref: ${bookingId})

Chercher: ${Vue.moment(booking.dateRange.start).format(
                        'dddd, Do MMMM YYYY'
                      )}, ${moment} ${
                        booking.freeTimeBooking.start
                          ? 'om ' + booking.freeTimeBooking.start
                          : ''
                      }
${
  booking.dateRange.end
    ? 'Retourner: ' +
      Vue.moment(booking.dateRange.end).format('dddd, Do MMMM YYYY')
    : ''
} ${booking.freeTimeBooking.end ? 'om ' + booking.freeTimeBooking.end : ''}


${result}

${mail.parts[1].value[lang]}`
                    }

                    const composedMail = {
                      subject:
                        mail.subject.value[lang] +
                        ' - ' +
                        Vue.moment(booking.dateRange.start).format(
                          'dddd, Do MMMM YYYY'
                        ),
                      body: body,
                      sendTo: user.email.value,
                      // TODO: Change to main adres of organisation responsible
                      replyTo: 'booking@velotheek.be',
                    }

                    resolve(composedMail)
                  })
                })
              }
            )
          })
        })
      })
      // End of promise
    })
  },
  editBooking: async (
    { state, rootState, dispatch, commit },
    { bookingId: bookingId }
  ) => {
    // Get Booking by Id
    const activeBookingsDb = new ActiveBookingsDB({
      userId: rootState.authentication.user.id,
    })
    const bookingToEdit = await activeBookingsDb.read(bookingId)
    // Set organisation
    commit('setSelectedOrgForBooking', bookingToEdit.organisation)

    // setLocationForProductSearch

    dispatch(
      'adminOrganisations/setLocationForProductSearch',
      { value: 0 },
      { root: true }
    )
    // setSelectedStock
    // Set dateRange
    commit('setBookingDateRange', bookingToEdit.dateRange)
    dispatch('getAvailableStockForBooking', {
      bookingStart: bookingToEdit.dateRange.start,
      bookingEnd: bookingToEdit.dateRange.end,
    }).then((availableStock) => {
      availableStock = availableStock.data
      // SOMETHING WRONG WITH EDITING
      dispatch('setStockForBookingModule', availableStock)
      // const selectedStockFromState = state.selectedStock
      const bookedSelectedStock = bookingToEdit.selectedStock

      for (const key in availableStock) {
        if (bookedSelectedStock.hasOwnProperty(key)) {
          bookedSelectedStock[key] = bookedSelectedStock[key]
        } else {
          bookedSelectedStock[key] = []
        }
      }

      commit('setEditingBooking', bookingId)
      commit('setSelectedStock', bookedSelectedStock)
      commit('setAvailableStock', availableStock)
      dispatch('goTo', '/booking')
    })
  },

  setTimeSlotBooking: ({ dispatch, commit }, { value: value }) => {
    commit('setTimeSlotBooking', { slotId: 0, value: false })
    commit('setTimeSlotBooking', { slotId: 1, value: false })
    commit('setTimeSlotBooking', { slotId: 2, value: false })

    commit('setSelectedTimeSlot', value)
    /* If the value is two, this means that the full day is selected */
    if (value === 2) {
      commit('setTimeSlotBooking', { slotId: 0, value: true })
      commit('setTimeSlotBooking', { slotId: 1, value: true })
    } else {
      commit('setTimeSlotBooking', { slotId: value, value: true })
    }
  },

  goTo: ({}, value) => {
    router.push(value)
  },

  getBookings: async ({ rootState }, { constraints, orderBy }) => {
    const activeBookingsDb = new ActiveBookingsDB({
      userId: rootState.authentication.user.id,
    })
    const activeBookings = await activeBookingsDb.readAll({
      constraints,
      orderBy,
    })
    return activeBookings
  },

  getAllBookings: async ({ commit, dispatch, rootState }) => {
    // Move this to somewhere more efficient
    await dispatch('adminOrganisations/getOrganisations', {}, { root: true })
    const activeOrg = Object.keys(
      rootState.authentication.user.organisations
    )[0]

    // Get the locations based on the active organisation

    await dispatch(
      'adminOrganisations/getLocations',
      { organisationId: activeOrg },
      { root: true }
    )

    const constraints = [
      // ['organisation', '==', activeOrg],
      // ['toDelete', '==', false],
    ]
    const orderBy = ['dateRange.start', 'asc']

    const activeBookings = await dispatch('getBookings', {
      constraints,
      orderBy,
    })
    const activeBookingsList = [...activeBookings]
    commit('setActiveBookingsList', activeBookingsList)
  },

  cancelBooking: async ({ state, dispatch, commit }, { bookingId }) => {
    /**
     * When we cancel, we just add a key "toDelete" with as value TRUE. This gets deleted through a firebase function
     **/
    // Get the date in 30 days
    // const deleteDate = Vue.moment().add(30, 'days').format('YYYY, MM, DD')

    // First: get all bookings, so we can find this booking. Todo: Super inefficient way of doing this
    await dispatch('getAllBookings')
    commit('deleteBooking', { bookingId: bookingId, toDelete: true })

    const booking = find(
      state.activeBookingsList,
      (booking) => booking.id === bookingId
    )

    dispatch('updateBookingFB', { data: booking })
    // const moveBookingOnCall = new StockFN({
    //   fn: 'moveBookingOnCall'
    // })

    // const response = await moveBookingOnCall.callFunction({ sourceCollection: 'activeBookings', targetCollection: 'cancelledBookings', id: bookingId}, null)

    // TODO: TRIGGER this after response. is not working
    dispatch('getAllBookings')
  },

  listenForBookingUpdates: ({ dispatch }) => {
    // TODO: Should i unsubscribe? https://firebase.google.com/docs/firestore/query-data/listen#detach_a_listener
    // TODO: Shouild move this firestore functionality  to the genericdb
    firebase
      .firestore()
      .collection('activeBookings')
      .orderBy('dateRange.start', 'asc')
      .onSnapshot(function (querySnapshot) {
        dispatch('getAllBookings')
        /* Could do it a bit chirurgically here, by checking what exactly changed and acting on that
            rather than just pumping in the whole thing again
        */
        // snapshot.docChanges().forEach(function(change) {
        // if (change.type === "added") {

        // }
        // if (change.type === "modified") {

        // }
        // if (change.type === "removed") {

        // }
      })
  },

  listenForBookingUpdatesUser: ({ dispatch, rootState }) => {
    /* TODO: Probably  just should listen to all bookings, for if other users add bookings */

    /* TODO: Should pass the user, rather than using rootState */
    firebase
      .firestore()
      .collection('activeBookings')
      .orderBy('dateRange.start', 'asc')
      .where('userId', '==', rootState.authentication.user.id)
      .onSnapshot(function (querySnapshot) {
        dispatch('getAllBookingsForUser')
        /* Could do it a bit chirurgically here, by checking what exactly changed and acting on that
            rather than just pumping in the whole thing again
        */
        // snapshot.docChanges().forEach(function(change) {
        // if (change.type === "added") {

        // }
        // if (change.type === "modified") {

        // }
        // if (change.type === "removed") {

        // }
      })
  },

  setDateMode: ({ dispatch, commit }, id) => {
    dispatch(
      'adminOrganisations/getOrganisationFB',
      { organisationId: id },
      { root: true }
    ).then((org) => {
      if (org.multipleBookings) {
        commit('setDateMode', 'range')
      } else {
        commit('setDateMode', null)
      }
    })
  },
  resetBooking: ({ commit }) => {
    commit('resetDisabledDate')
    commit('setAvailableStock', {})
    commit('resetSelectedStock')
    commit('resetBookingDateRange')
    commit('resetDateAsSelected')

    // Reset Time Slots
    commit('setSelectedTimeSlot', null)

    commit('adminOrganisations/setSelectedLocation', null, { root: true })
  },
}
