<template>
  <svg
    id="velo__logo"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 260.8 153.1"
    style="enable-background:new 0 0 260.8 153.1;"
    xml:space="preserve"
  >
    <g>
      <circle class="st0" cx="130.6" cy="77" r="70.2" />
      <path
        class="st1"
        :style="{ fill: color }"
        d="M153.5,53.9l-1.6-0.9l-4.1-10.2c0.6-0.8,2.4-1.7,4.5-1.7c0.7,0,1.4,0.1,2.1,0.3c0,0,0.1,0,0.1,0v11.2
		L153.5,53.9z M151.5,70.5C151.5,70.6,151.5,70.6,151.5,70.5l0.2-7.7l-4.3,5.3C148.5,69.3,149.8,70.3,151.5,70.5z M143.2,57.5
		c0,0.6,0,1.2,0.2,2c0,0.2,0.1,0.4,0.2,0.7l3.7-2.1L143.2,57.5z M144,61.7c0.5,1.5,1.3,3.4,2.4,5.1l5.4-6.5l0-1.6l-1.8-0.2L144,61.7
		z M151.2,55.8l-0.7-1.7l-6.5-3.9c-0.3,0.9-0.6,1.6-0.6,1.9c-0.1,1.2-0.2,2.4-0.2,3.8l6.4,0.9L151.2,55.8z M164,51.7
		c-0.4-1.8-1.4-3.9-2.2-5.5l-5.6,6.9V55l1.6,0.3L164,51.7z M146.8,44.5c-0.7,1.3-1.4,2.8-2.1,4.2l4.9,2.9L146.8,44.5z M156.8,42.3
		c-0.2-0.1-0.4-0.2-0.7-0.3v8.5l4.7-5.7C159.9,43.8,159,43.3,156.8,42.3z M184.2,123.3c-13,15-32.2,24.5-53.6,24.5
		c-13.9,0-26.8-4-37.7-10.9c0-1.3,0-3.3-0.1-6.2c-0.2-11.5-0.5-37.3-0.4-42.3c1.4,0.1,3.9,0.1,7.5,0c3.7,0,8.8,0,9.5,0.1
		c0.2,0.1,0.5,0.1,0.7,0c0.2-0.1,0.4-0.3,0.5-0.5c0.4-0.9,0.3-2.6,0.2-4.1l0-0.2c0-0.3-0.1-0.5-0.3-0.6s-0.4-0.2-0.7-0.2
		c-1,0.1-6.6,0.1-10.6,0.1c-1.8,0-3.4,0-4.6,0c-2.1,0-21.6,0.4-22.5,0.5c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.6,0.7-0.2,3.3
		c0,0.1,0.1,0.2,0.1,0.3c0,0,0,0.1,0,0.1c0.2,0.5,0.5,0.6,1,0.8c0.2,0.1,0.6,0.1,0.8,0c0.8-0.4,6.7-0.3,13.6,0.1
		c-0.1,5.6,0.1,32.4,0.3,44.6C70.5,120,59.8,99.7,59.8,77c0-11.5,2.8-22.4,7.7-32.1c0.7,1.1,1.4,2.4,2.2,3.7
		c5.5,9.2,13,21.9,14.7,24c0.1,0.1,0.2,0.2,0.3,0.2c0.3,0.1,0.5,0.3,0.7,0.4c0.6,0.3,1.2,0.7,2.1,0.7c0.1,0,0.3,0,0.4,0
		c0.3,0,0.6-0.2,0.7-0.5c0.2-0.4,0.3-0.6,0.5-0.8c0.3-0.5,0.5-0.8,0.7-1.6c0.1-0.3,5.6-22.4,6.7-26.8c0.3-0.9,0.5-1.8,0.8-2.5
		c0.3-1,0.6-1.9,0.9-2.9c0.1-0.4-0.1-0.9-0.5-1.1c-0.7-0.3-1-0.4-1.4-0.5c-0.4-0.1-1-0.3-2.4-0.9c-0.4-0.2-1,0-1.2,0.4
		c-0.4,0.8-5.6,18.1-6.2,23.4c0,0.4-0.1,0.9-0.2,1.5c-0.1,0.9-0.2,2.2-0.4,3.4c-0.5-1.1-1.1-2.3-1.4-2.9c-0.9-1.7-1.9-3.4-2.9-5.1
		c-3.5-6.2-7.1-12.6-10.9-17.7c12.5-19.9,34.7-33.2,60-33.2c39.1,0,70.9,31.7,70.9,70.9c0,15.8-5.2,30.4-14,42.2
		c-1.6-3.6-3.8-7.8-5.9-12c-1.5-2.9-2.9-5.6-4-7.9c1-1.4,2.2-2.7,3.4-3.9c1.3-1.3,2.6-2.7,3.7-4.3c0.3-0.4,0.2-0.9-0.1-1.2
		c-0.5-0.5-0.8-0.7-1.2-1c-0.3-0.2-0.6-0.5-1.1-0.9c-0.4-0.4-0.9-0.4-1.3,0c-1.1,1-2,2.3-3,3.5c-0.8,1-1.5,2-2.3,2.8
		c-1.2,1.5-2.2,2.5-3.5,3.9c0-0.7,0-1.4,0.1-2.1c0-0.5,0.1-1.1,0.1-1.7c0-0.4,0-0.8,0-1.2c0-1.7,0.1-3.2-0.9-4
		c-0.2-0.2-0.5-0.2-0.7-0.2c-0.2,0-0.5,0.1-0.7,0.1c-0.8,0.1-1.8,0.3-2.8,0.6c-0.5,0.2-0.7,0.7-0.6,1.1c0.6,2.1,0.6,4.4,0.6,6.6
		c0,0.4,0,0.8,0,1.3c0,3.6-0.3,15.1-0.3,15.8c0,0,0,0.1,0,0.1c0,0.1,0,8.9,0.6,9.7c0.2,0.2,0.5,0.4,0.7,0.4c0.1,0,0.2,0,0.3,0
		c0.4-0.1,0.8-0.2,1.1-0.3c0.8-0.2,1.5-0.3,2.1-0.7c0.3-0.2,0.4-0.5,0.4-0.8c0.1-0.2,0.2-0.5,0.1-0.9c0.1-3.9,0-7.3,0-10.8
		c0-2.1-0.1-4.2-0.1-6.5c0,0,0-0.1,0-0.2c0.7-0.4,1.1-0.9,1.6-1.4c0.1-0.1,0.2-0.3,0.3-0.4c2.5,5.3,6.6,14.1,9.2,18.8l0.1,0.2
		C183.6,122.4,183.8,122.9,184.2,123.3z M175.5,57.4c0.1,0.4,0.2,0.9,0.2,1.3c0.1,0.8,0.2,1.5,0.6,2.1c0.2,0.3,0.4,0.4,0.8,0.4
		c0.1,0,0.1,0,0.2,0c1.8-0.3,11.6-0.8,12.9-0.7c0.3,0,0.5-0.1,0.7-0.3s0.3-0.5,0.2-0.7l-0.6-3.4c-0.1-0.5-0.5-0.8-0.9-0.7
		c-1.9,0.1-4.1,0.3-6.3,0.4c-5.3,0.3-6.9,0.4-7.3,0.6C175.6,56.5,175.4,57,175.5,57.4z M126.3,42.5c0.1,4.2,0.3,8.6,0.3,10.9
		c0.2,7.3,0.5,15.2,0.9,24.3c0,0.8,0,2.1-0.1,3.3c-0.2,4.4-0.2,5.2,0.8,5.5c0.2,0.1,0.3,0.1,0.5,0c0.3,0,1.4,0.1,2.4,0.1
		c1.6,0.1,3.7,0.3,5.6,0.3c1.5,0,2.8-0.1,3.8-0.3c0.4-0.1,0.7-0.5,0.7-0.9c0,0-0.1-2.5-0.2-3.1c0-0.4-0.4-0.8-0.8-0.8
		c-0.2,0-1.1,0-3.3-0.1c-1.2,0-2.7,0-3.6,0c-0.3-4.9-0.7-12.3-0.7-12.4c0-0.2-0.2-4.7-0.3-9.8c-0.2-6.3-0.4-14.3-0.6-19.6
		c0-1.1-0.1-2.1-0.2-3.1c0-0.5-0.1-1-0.1-1.5c-0.2-1.5-0.3-2.7-0.1-3.6c0.1-0.4-0.1-0.9-0.6-1.1c-1.2-0.5-3.1-0.2-4.2,0.1
		c-0.4,0.1-0.7,0.4-0.7,0.8C125.9,32.2,126.1,36.1,126.3,42.5z M106.9,31.2c0.4,0.9,1.2,1.6,2.5,2c0.1,0,0.2,0,0.3,0
		c0.2,0,0.5-0.1,0.6-0.3c0.9-0.9,5.6-6.3,5.8-6.5c0.7-0.8,2.5-4.1,2.7-4.7c0.1-0.3,0-0.6-0.1-0.8c-0.2-0.2-0.4-0.4-0.7-0.4
		c-0.9-0.1-1.6-0.2-2.7-0.5c-0.3-0.1-0.6,0-0.8,0.1c-0.2,0.1-0.3,0.3-0.3,0.5c-0.4,0.9-4.5,7.2-7,9.5
		C106.8,30.4,106.7,30.8,106.9,31.2z M119.9,110c0.2-4.7,0.5-9.1,0.3-13.4c0-0.4-0.3-0.7-0.6-0.8c-0.9-0.4-2.1-0.9-3.2-0.9
		c-0.1,0-0.2,0-0.3,0.1c-0.2,0-0.5,0-0.7,0.2c-0.2,0.2-0.4,0.5-0.4,0.8c0.1,2.8,0.1,9.9-0.5,15.2c-1.9-0.3-3.8-0.3-5.7-0.3
		c-1.9,0-3.9-0.1-5.8-0.3c0-1.7,0-3.4,0.1-5c0.1-2.1,0.2-4.1,0.1-6.1c-0.1-1.4-0.3-2.6-0.6-3.7c-0.1-0.4-0.4-0.6-0.8-0.6
		c-0.3,0-0.5-0.1-0.8-0.1c-0.8-0.1-1.7-0.2-2.6-0.1c-0.3,0-0.5,0.2-0.7,0.4c-0.2,0.2-0.2,0.5-0.2,0.7c0.2,1.2,0.3,2.4,0.4,3.7
		c0.2,3.1,0.1,6.3-0.1,9.8c-0.1,1.4-0.1,2.7-0.1,4.1c-0.1,2.9-0.1,6.2-0.2,9.3c0,2.4,0,4.7-0.1,6.7c0,0.3,0.1,0.6,0.4,0.8
		c0.3,0.2,0.6,0.2,0.9,0.1c0.2-0.1,0.9-0.2,1.4-0.2c0.6,0,1.1-0.1,1.7-0.2c0.4-0.1,0.7-0.5,0.7-1c-0.2-2.5,0-5.6,0.1-8.6
		c0.1-1.9,0.2-3.7,0.2-5.2c2.6,0,4.6,0.1,6.7,0.3c1.4,0.1,2.8,0.2,4.5,0.3c0,3,0,5.5,0.2,8.1c0.1,1.9,0.3,3.8,0.7,5.8
		c0.1,0.4,0.4,0.7,0.9,0.8l0.5,0c0.6,0,1.2,0.1,1.9,0.1c0.4,0,0.9,0,1.2-0.1c0.5-0.1,0.8-0.5,0.8-1c0-0.5-0.1-0.9-0.2-1.3
		c-0.1-0.6-0.2-1.1-0.2-1.7c0-0.5,0-1-0.1-1.5c0-0.5,0-1-0.1-1.5C119.4,119.1,119.6,114.5,119.9,110z M122.1,75.6c0-0.5-0.1-2,0-2.9
		c0-0.3,0-0.5-0.2-0.7c-0.2-0.2-0.4-0.3-0.7-0.3c-1.1,0-2.4,0.1-3.8,0.1c-2.4,0.1-5,0.3-6.7,0.1c-0.2-2-0.2-4.3-0.2-6.5v-4.8
		c0-0.9,0-1.8,0-2.7c0.6,0,1.1,0,1.7,0.1c0.6,0,1.3,0.1,1.9,0.1c1.3,0,2.4-0.1,3.3-0.4c0.5-0.1,0.7-0.6,0.6-1.1
		c-0.2-1.2-0.2-2-0.2-2.8c0-0.5-0.3-0.8-0.8-0.9c-1.5-0.2-4.2,0.1-6.4,0.3c-0.2,0-0.4,0-0.6,0.1c-0.1-3.9-0.5-8-1.5-12.8
		c1.6-0.1,3.2-0.4,4.7-0.6c2-0.3,4-0.6,5.9-0.6c0.5,0,0.8-0.3,0.9-0.8c0.1-0.8,0.2-3-0.6-4c-0.3-0.4-0.7-0.6-1-0.6
		c-0.1,0-0.2,0-0.4,0c-3,0.9-8.3,1.5-11.4,1.8c-2.2,0.2-2.7,0.3-3,0.4c-0.4,0.1-0.6,0.5-0.6,0.9c0,0.7,0,1,0.1,1.4
		c0,0.2,0,0.3,0.1,0.6c1.7,15.6,2.4,28.1,2.3,36.9c0,0.4,0.3,0.8,0.7,0.9c1.8,0.4,5.3,0.6,8.3,0.6c1.2,0,2.2,0,3.3-0.1
		c0.4-0.1,1.1-0.2,1.8-0.3c0.7-0.1,1.4-0.2,1.9-0.3C121.9,76.5,122.2,76,122.1,75.6z M141.6,91.4c-0.3-0.5-0.7-0.6-0.9-0.7
		c-0.1,0-0.2,0-0.4,0c-3,0.7-8.4,0.8-11.5,1c-2.2,0.1-2.8,0.1-3.1,0.2c-0.4,0.1-0.6,0.4-0.7,0.8c0,0.2,0,1.1,0,3.5
		c0.1,7.9,0.4,26.5-0.4,35.4c0,0.4,0.2,0.8,0.7,1c1.8,0.5,5.3,1,8.3,1.2c1,0.1,1.9,0.1,2.7,0.1c0.2,0,0.3,0,0.5,0
		c0.4-0.1,1.1-0.1,1.8-0.1c0.7,0,1.5-0.1,1.9-0.1c0.5-0.1,0.8-0.5,0.8-0.9c0-0.5,0-2,0.2-2.8c0.1-0.3,0-0.5-0.2-0.7
		c-0.2-0.2-0.4-0.3-0.7-0.4c-1.1-0.1-2.4-0.1-3.8-0.2c-1.5,0-3.1-0.1-4.6-0.2c-0.8-0.1-1.5-0.1-2.1-0.2c0-2.4,0.3-8.3,0.6-11.3
		c0.1-0.9,0.1-1.8,0.2-2.7c0.6,0,1.1,0.1,1.7,0.2c0.6,0.1,1.3,0.1,1.9,0.2c1.3,0.1,2.4,0,3.3-0.1c0.5-0.1,0.8-0.5,0.7-1
		c-0.2-1.2-0.1-2,0-2.8c0-0.5-0.3-0.9-0.7-1c-1.5-0.3-4.2-0.3-6.4-0.2c-0.2,0-0.4,0-0.6,0c0.2-3.9,0-8-0.5-12.9
		c1.7,0,3.2-0.1,4.8-0.3c2.1-0.2,4-0.3,6-0.2c0.4,0,0.9-0.3,1-0.7C142.1,94.6,142.3,92.5,141.6,91.4z M164.3,123.4
		c0-0.5-0.1-2,0-2.9c0-0.3,0-0.5-0.2-0.7c-0.2-0.2-0.4-0.3-0.7-0.3c-1.1,0-2.4,0.1-3.8,0.1c-2.4,0.1-5,0.3-6.7,0.1
		c-0.2-2-0.2-4.3-0.2-6.5v-4.8c0-0.9,0-1.8,0-2.7c0.6,0,1.1,0,1.7,0.1c0.6,0,1.3,0.1,1.9,0.1c1.3,0,2.4-0.1,3.3-0.4
		c0.5-0.1,0.7-0.6,0.6-1.1c-0.2-1.2-0.2-2-0.2-2.8c0-0.5-0.3-0.8-0.8-0.9c-1.5-0.2-4.2,0.1-6.4,0.3c-0.2,0-0.4,0-0.6,0.1
		c-0.1-3.9-0.5-8-1.5-12.8c1.6-0.1,3.2-0.4,4.7-0.6c2-0.3,4-0.6,5.9-0.6c0.5,0,0.8-0.3,0.9-0.8c0.1-0.8,0.2-3-0.6-4
		c-0.3-0.4-0.7-0.6-1-0.6c-0.1,0-0.2,0-0.4,0c-3.9,1.1-7.6,1.5-11.6,1.8l-1.6,0.1l-0.1,0c-0.4,0-0.6,0-1.2,0.2
		c-0.4,0.1-0.6,0.5-0.6,0.9c0,0.7,0,1,0.1,1.4c0,0.2,0,0.3,0.1,0.6c1.7,15.6,2.4,28.1,2.3,36.9c0,0.4,0.3,0.8,0.7,0.9
		c1.8,0.4,5.3,0.6,8.3,0.6c1.2,0,6-0.5,6.9-0.6C164,124.3,164.4,123.8,164.3,123.4z M167.7,69.7c1.9-2.8,2.9-6.8,2.9-11
		c0-4.1-0.7-9.9-3.8-14.5c-1.8-2.7-4.6-5-7.9-6.5c-2.7-1.1-5.2-1.7-7.5-1.7c0,0,0,0,0,0c-0.1,0-0.2,0-0.3,0c-2.6,0-4.7,0.9-6.3,2.2
		l-0.5,0.2l0.1,0.2c-3.4,3.1-4.7,8.1-5.2,10.3c-0.9,3.4-1.3,6.6-1.3,9.6c0,2.5,0.3,5,0.9,7.3c1.7,6.6,7.5,9,11.7,10.1
		c1.6,0.4,3.2,0.6,4.7,0.6C160.2,76.6,164.7,74.1,167.7,69.7z M165,56.5c0-0.5-0.1-0.9-0.2-1.4c-0.1-0.6-0.3-1.2-0.4-1.7
		c0,0,0,0,0,0l-4.1,2.3L165,56.5z M154.5,59.4l-1.1,1.4l-0.2,10c1.1,0.1,2.1,0.3,3.1,0.3c1.4,0,2.5-0.2,3.4-0.6l-3.8-10.2
		L154.5,59.4z M161,69.4c1-1,1.9-2.3,2.3-2.8c0.1-0.1,0.2-0.2,0.2-0.3c0.2-0.3,0.4-0.7,0.5-1l-6-3.7L161,69.4z M158.1,56.9l-1.4,0.8
		l0.5,1.4l7.5,4.6c0.3-1.1,0.5-2.4,0.5-3.7c0-0.6,0-1.2,0-1.9L158.1,56.9z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    color: String
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.velo__logo {
  opacity: 0;
}

.st0 {
  fill: none;
}
</style>
