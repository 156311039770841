<template>
  <v-app>
    
    <nav-hamburger v-if="admin"></nav-hamburger>
    <nav-appbar :admin="admin"></nav-appbar>
    
    <v-content>
      <main-notification></main-notification>
      <router-view></router-view>
    </v-content>

    <!-- <nav-bottom-bar v-if="user" :admin="admin"></nav-bottom-bar> -->

    <new-content-available-toastr
      v-if="newContentAvailable"
      class="new-content-available-toastr"
      :refreshing-app="refreshingApp"
      @refresh="serviceWorkerSkipWaiting"
    ></new-content-available-toastr>
    <apple-add-to-home-screen-modal
      v-if="showAddToHomeScreenModalForApple"
      class="apple-add-to-home-screen-modal"
      @close="closeAddToHomeScreenModalForApple(false)"
    >
    </apple-add-to-home-screen-modal>
  </v-app>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import navAppbar from '@/components/Nav-AppBar'
import navBottomBar from '@/components/Nav-BottomBar'
import navHamburger from '@/components/Nav-Hamburger'
import mainNotification from '@/components/Main-Notification'
import NewContentAvailableToastr from '@/components/NewContentAvailableToastr'
import AppleAddToHomeScreenModal from '@/components/AppleAddToHomeScreenModal'

export default {
  components: {
    navAppbar,
    navBottomBar,
    navHamburger,
    mainNotification,
    NewContentAvailableToastr,
    AppleAddToHomeScreenModal
  },
  computed: {
    ...mapGetters('app', ['newContentAvailable']),
    ...mapState('app', ['showAddToHomeScreenModalForApple', 'refreshingApp']),
    ...mapState('authentication', ['admin', 'userLang', 'user'])
  },
  methods: {
    ...mapActions('app', [
      'closeAddToHomeScreenModalForApple',
      'serviceWorkerSkipWaiting'
    ]),
    ...mapActions('translations', ['getTranslations', 'getLanguages'])
  },
  created() {
    this.getLanguages()
  }
}
</script>

<style lang="scss">
@import '@/theme/animate.scss';

.apple-add-to-home-screen-modal {
  position: absolute;
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
  height: fit-content;
  width: fit-content;
  margin: auto;
  z-index: 1000;
}
</style>
