export default {
  translations: [],
  languages: [],
  translationHeaders: [],
  defaultTranslation: {
    ref: "",
    context: ["", "", ""],
    desc: ["", "", ""],
    value: ["", "", ""]
  },
  newTranslation: {
    ref: "",
    context: ["", "", ""],
    desc: ["", "", ""],
    value: ["", "", ""]
  }
}