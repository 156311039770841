// import { find, orderBy } from 'lodash'
const _ = require('lodash')
export default {
  getLanguage: state => lang => _.find(state.languages, language => language.id === lang)  ,
  copy: state => (ref, lang) => {
    const translationObject = _.find(
      state.translations,
      translation => translation.ref === ref
    )
    if (translationObject !== undefined) {
      if (lang === undefined || lang === null) {
        translationObject.value[0]
      } else {
        return translationObject.value[lang]
      }
    } else {
      return ''
    }
  },
  orderedLanguages: state  => _.orderBy(state.languages, x => x.id)
 
}
