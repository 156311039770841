import { find } from 'lodash'
import TranslationsDB from '@/firebase/translations-db'
import LanguagesDB from '@/firebase/languages-db'

export default {
  getTranslations: async ({ dispatch, commit }) => {
    const translationsDb = new TranslationsDB()
    const translations = await translationsDb.readAll({ constraints: null })
    dispatch('setTranslationHeaders', { translation: translations[0] })
    commit('setTranslations', translations)
  },
  getLanguages: async ({ dispatch, commit }) => {
    const languagesDb = new LanguagesDB()
    const languages = await languagesDb.readAll({ constraints: null })
    dispatch('getTranslations')
    commit('setLanguages', languages)
  },
  setTranslationHeaders: ({ commit, state }) => {
    // stockToCreateHeaders: [{ text: 'location', value: 'location', sortable: true }, { text: 'reference', value: 'reference', sortable: true }],
    const headers = [{ text: 'referentie', value: 'ref', sortable: true }]
    state.languages.forEach(element => {
      headers.push({
        text: element.name,
        value: `value[${element.id}]`,
        sortable: true
      })
    })
    commit('setTranslationHeaders', headers)
  },
  addTranslation: async ({ dispatch }, { translation: translation }) => {
    const translationsDb = new TranslationsDB()
    const createdTranslation = await translationsDb.create(translation)
    dispatch('getTranslations')
  },
  saveTranslation: async (
    { state, dispatch },
    { value: value, lang: lang, ref: ref }
  ) => {
    const translation = find(
      state.translations,
      translation => translation.ref === ref
    )
    translation.value[lang] = value
    const translationsDb = new TranslationsDB()
    const savedTranslation = await translationsDb.update(translation)
    dispatch('getTranslations')
  }
}
