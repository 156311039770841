import { isNil, keys, cloneDeep } from 'lodash'
import firebase from 'firebase/app'
import firestore from './async-firestore'
import firebaseFunctions from './async-firebaseFunctions'

export default class GenericDB {
  constructor(collectionPath) {
    this.collectionPath = collectionPath
  }
  /**
   * Create a document in the collection
   * @param data
   * @param id
   */
  async create(data, id = null) {
    const collectionRef = (await firestore()).collection(this.collectionPath)
    const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp()
    const dataToCreate = {
      ...data,
      createTimestamp: serverTimestamp,
      updateTimestamp: serverTimestamp
    }
    const createPromise = isNil(id) // Create doc with generated id
      ? collectionRef.add(dataToCreate).then(doc => doc.id) // Create doc with custom id
      : collectionRef
          .doc(id)
          .set(dataToCreate)
          .then(() => id)
    const docId = await createPromise
    return {
      id: docId,
      ...data,
      createTimestamp: new Date(),
      updateTimestamp: new Date()
    }
  }
  /**
   * Read a document in the collection
   * @param id
   */
  async read(id) {
    const result = await (await firestore())
      .collection(this.collectionPath)
      .doc(id)
      .get()
    const data = result.exists ? result.data() : null
    if (isNil(data)) return null
    this.convertObjectTimestampPropertiesToDate(data)
    return { id, ...data }
  }
  /**
   * Read all documents in the collection following constraints
   * @param constraints
   */
  async readAll({ constraints = null, orderBy = null, limit = null }) {
    // console.log(constraints, orderBy, limit )
    const collectionRef = (await firestore()).collection(this.collectionPath)
    let query = collectionRef
    if (constraints) {
      constraints.forEach(constraint => (query = query.where(...constraint)))
    }
    if (orderBy) {
      query = query.orderBy(orderBy[0], orderBy[1])
    }
    if (limit) {
      query = query.limit(limit)
    }
    const formatResult = result =>
      result.docs.map(ref => {
        // TODO: I fixed this because the id of languages was getting overwritten by the firebase id. I should rename "id" to langId for example, so there is no conflict with the firebase id and the language id
        var id = ref.id
        if(this.collectionPath === 'languages') id = ref.data().id
      
        return this.convertObjectTimestampPropertiesToDate({
          ...ref.data(),
          id: id
        })
      }
      )
    return query.get().then(formatResult)
  }
  /**
   * Get a collectionGroup
   * @param id
   */
  async collectionGroup(constraints = null) {
    const collectionRef = (await firestore()).collectionGroup(
      this.collectionPath
    )
    let query = collectionRef
    if (constraints) {
      constraints.forEach(constraint => (query = query.where(...constraint)))
    }
    const formatResult = result =>
      result.docs.map(ref =>
        this.convertObjectTimestampPropertiesToDate({
          id: ref.id,
          ...ref.data()
        })
      )
    return query.get().then(formatResult)
  }
  /**
   * Get Collection size
   * @param id
   */
  async collectionSize() {
    const collectionRef = (await firestore()).collection(this.collectionPath)
    const query = collectionRef
    query.get().then(query => {
      if (query.size === undefined) return 0
      return query.size
    })
  }
  /**
   * Update a document in the collection
   * @param data
   */
  async update(data) {
    const id = data.id
    const clonedData = cloneDeep(data)
    delete clonedData.id
    await (await firestore())
      .collection(this.collectionPath)
      .doc(id)
      .update({
        ...clonedData,
        updateTimestamp: firebase.firestore.FieldValue.serverTimestamp()
      })
    return id
  }
  /**
   * Delete a document in the collection
   * @param id
   */
  async delete(id) {
    return (await firestore())
      .collection(this.collectionPath)
      .doc(id)
      .delete()
  }
  /**
   * Convert all object Timestamp properties to date
   * @param obj
   */
  convertObjectTimestampPropertiesToDate(obj) {
    keys(obj)
      .filter(prop => obj[prop] instanceof Object)
      .forEach(prop =>
        obj[prop] instanceof firebase.firestore.Timestamp
          ? (obj[prop] = obj[prop].toDate())
          : this.convertObjectTimestampPropertiesToDate(obj[prop])
      )
    return obj
  }
  /**
   * Call a firebase function
   * @param data
   */
  async callFunction(data, constraints = null) {
    /**
     * I'm using the "this.collectionPath" here, but that name doesn't make any sense anymore now I'm using it also for functions.
     * Maybe change to something like "path"?
    */

    
    const firestoreFunctions = await firebaseFunctions()
    const firestoreFN = await firestoreFunctions.httpsCallable(this.collectionPath);
    const functionRes = await firestoreFN({ data: data, constraints: constraints })
    
    return functionRes;
  }
}




