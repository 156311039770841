<template>
  <span> {{ copy(item, lang) }}</span>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    item: String,
    lang: Number
  },
  computed: {
    ...mapGetters('translations', ['copy'])
  }
}
</script>
