import state from './notifications.state'
import mutations from './notifications.mutations'
import actions from './notifications.actions'
import getters from './notifications.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
