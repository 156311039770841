<template>
  <v-app-bar app color="primary" elevation="0">
    <v-app-bar-nav-icon
      v-if="admin"
      @click.stop="setDrawer()"
    ></v-app-bar-nav-icon>
    <transition
      appear
      name="custom-classes-transition"
      enter-active-class="animated tada"
      leave-active-class="animated bounceOutRight"
    >
      <router-link to="/dashboard">
        <app-logo class="topBar__logo" color="#ee2a7b"></app-logo>
      </router-link>
    </transition>

    <template v-if="!$vuetify.breakpoint.smAndDown">
      <router-link v-if="isUserLoggedIn" to="/guide">
        <v-btn class="ma-2" outlined>
          <copy item="guideMenu" :lang="userLang"></copy>
        </v-btn>
      </router-link>

      <router-link v-if="isUserLoggedIn" to="/dashboard">
        <v-btn class="ma-2" outlined>
          <copy item="navHome" :lang="userLang"></copy>
        </v-btn>
      </router-link>

      <router-link
        v-if="isUserLoggedIn && activeRoute !== '/booking'"
        to="/booking"
      >
        <v-btn class="ma-2" outlined>
          <!-- {{ activeRoute }} -->
          <copy item="startNewBooking" :lang="userLang"></copy>
        </v-btn>
      </router-link>
      <v-btn
        v-else-if="isUserLoggedIn && activeRoute == '/booking'"
        class="ma-2"
        outlined
        @click="resetBooking"
      >
        <copy item="resetBooking" :lang="userLang"></copy>
      </v-btn>

      <div class="flex-grow-1"></div>
      <div v-if="!networkOnLine" class="nav-item offline-label">
        <!-- TODO: Translate -->
        <v-btn>Offline</v-btn>
      </div>

      <span
        v-if="isUserLoggedIn && user && user.firstName"
        style="padding-right: 2rem"
        >{{ user.firstName.value }}</span
      >

      <!-- <template v-if="admin">
      
      Active Organisation:
      <v-select
        v-if="isUserLoggedIn && user && user.organisations && organisations"
        style="margin-top: 30px;margin-right:30px;margin-left:30px;max-width:200px;"
        :value="userActiveOrganisation"
        :items="getArrayOfUserLocations(user.organisations)"
        outlined
        @input="
          changeUserActiveOrganisation({ id: $event.id, role: $event.role })
        "
      >
        <template v-slot:selection="{ item, id }">
          {{ getOrganisationById(item.id).name.value[userLang] }}
        </template>
        <template v-slot:item="{ item, id }">
          {{ getOrganisationById(item.id).name.value[userLang] }}
        </template>
      </v-select>
    </template> -->
      <v-btn
        v-if="isUserLoggedIn && networkOnLine"
        class="ma-2"
        outlined
        @click="logout"
      >
        Logout
      </v-btn>
      V1.0.3
      <div v-if="!networkOnLine" class="nav-item offline-label">
        <!-- TODO: Translate -->
        Offline
      </div>
    </template>
    <template v-if="$vuetify.breakpoint.smAndDown">
      <div class="flex-grow-1"></div>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on"> Menu </v-btn>
          V1.0.3
        </template>

        <v-list>
          <v-list-item>
            <router-link v-if="isUserLoggedIn" to="/guide">
              <v-btn class="ma-2" outlined>
                <copy item="guideMenu" :lang="userLang"></copy>
              </v-btn>
            </router-link>
          </v-list-item>
          <v-list-item>
            <router-link v-if="isUserLoggedIn" to="/dashboard">
              <v-btn class="ma-2" outlined>
                <copy item="navHome" :lang="userLang"></copy>
              </v-btn>
            </router-link>
          </v-list-item>

          <v-list-item>
            <router-link
              v-if="isUserLoggedIn && activeRoute !== '/booking'"
              to="/booking"
            >
              <v-btn class="ma-2" outlined>
                <!-- {{ activeRoute }} -->
                <copy item="startNewBooking" :lang="userLang"></copy>
              </v-btn>
            </router-link>
            <v-btn v-else class="ma-2" outlined @click="resetBooking">
              <!-- TODO: Fix translation -->
              <!-- TODO: Trigger reset function -->
              <copy item="resetBooking" :lang="userLang"></copy>
            </v-btn>
          </v-list-item>

          <v-list-item>
            <!-- TODO: Translate -->
            <v-btn
              v-if="isUserLoggedIn && networkOnLine"
              class="ma-2"
              outlined
              @click="logout"
            >
              Logout
            </v-btn>
          </v-list-item>

          <!-- <v-list-item-title>{{ item.title }}</v-list-item-title> -->
        </v-list>
      </v-menu>
    </template>
  </v-app-bar>
</template>

<script>
import firebase from 'firebase/app'
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import appLogo from '@/components/App-Logo'

export default {
  components: { appLogo },
  props: {
    admin: Boolean,
  },
  data() {
    return {
      activeRoute: null,
    }
  },
  computed: {
    ...mapGetters('authentication', ['isUserLoggedIn']),
    ...mapGetters('adminUsers', ['getArrayOfUserLocations']),
    ...mapGetters('adminOrganisations', ['getOrganisationById']),
    ...mapState('adminOrganisations', ['activeOrganisation', 'organisations']),
    ...mapState('authentication', [
      'user',
      'userLang',
      'userActiveOrganisation',
    ]),
    ...mapState('app', [
      'networkOnLine',
      'appTitle',
      'appShortTitle',
      'drawer',
    ]),
    ...mapState('translations', ['languages', 'translations']),
  },
  methods: {
    ...mapMutations('app', ['setDrawer']),
    // ...mapMutations('authentication', ['setUserActiveOrganisation']),
    ...mapActions('authentication', [
      'checkAuth',
      'changeUserActiveOrganisation',
    ]),
    ...mapActions('adminBookings', ['resetBooking']),
    async logout() {
      await firebase.auth().signOut()
    },
  },
  watch: {
    $route(newValue, oldValue) {
      this.activeRoute = newValue.fullPath
      if (newValue.fullPath === '/booking') {
        this.resetBooking()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.v-application a {
  color: unset;
}

.topBar {
  &__logo {
    height: 5rem;
    margin-top: 2rem;
  }
}
</style>
