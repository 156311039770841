import { cloneDeep } from 'lodash'

export default {
  setTranslations: (state, value) => (state.translations = value),
  setLanguages: (state, value) => (state.languages = value),
  setTranslationHeaders: (state, value) => (state.translationHeaders = value),
  setNewTranslation: (state, { field: field, value: value, key: key }) => {
    if(key === null) {
      state.newTranslation[field] = value
    } else {
      state.newTranslation[field][key] = value
    }
  },
  resetNewTranslation: (state, value) => {
    console.log('here')
    state.newTranslation = cloneDeep(state.defaultTranslation)
  },
}
