const _ = require('lodash')


export default {
  getUserRoleById: state => roleId =>
    _.find(state.userRoles, role => role.value === roleId),
  getUserRoleByIdAsArray: state => roleId => {
    const role = _.find(state.userRoles, role => {
      role.value === roleId
    })
    return []
  },
  getUserById: state => userId => {
    if(state.users !== null) {
      return _.find(state.users, user => user.id === userId)
    } else {
      return false
    }
  },
    
    
  getArrayOfUserLocations: state => organisations => {
    const userLocationsArray = []
    let el
    for (el in organisations) {
      // Some work here
      if (organisations[el] >= 50) {
        userLocationsArray.push({ id: el, role: organisations[el] })
      }
    }
    if (el !== undefined) {
      // There was at least one element
      return userLocationsArray
    }
  }
}
