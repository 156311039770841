import state from './translations.state'
import mutations from './translations.mutations'
import actions from './translations.actions'
import getters from './translations.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
