import productsDB from '@/firebase/products-db'
import stockDB from '@/firebase/stock-db'

export default {
  /**
   * Reset the spec
   */
  resetSpec: ({ commit }) => {
    commit('resetNewSpec')
  },
  /**
   * When click on new type of sped (specDefaults), the newSpec gets populated with the correct fields
   * */
  setNewSpecDefaults: ({ commit }, spec) => {
    commit('setNewSpecFields', spec)
  },
  /**
   * Add a spec to a product
   */
  addSpec: ({ state, commit, dispatch }) => {
    // TODO: Built in more checks before continuing (are all fields populated?)
    if (state.newSpec.value === null) return
    const spec = state.newSpec
    commit('productAddSpec', spec)
    dispatch('resetSpec')
  },
  /**
   * Fetch products
   */
  getProductsFB: async (
    { rootState, commit },
    { userId: userId, constraints: constraints }
  ) => {
    const productDB = new productsDB(userId)
    const products = await productDB.readAll({ constraints })
    return products
  },
  /**
   * Fetch product
   */


  
  getProductFB: async (
    { rootState, commit },
    { userId: userId, productId: productId }
  ) => {
    const productDB = new productsDB(userId)
    const product = await productDB.read(productId)
    return product
  },
  /**
   * Fetch products
   */
  // getProducts: ({ rootState, commit, dispatch }) => {
  //   dispatch('getProductsFB', {
  //     userId: rootState.authentication.user.id,
  //     constraints: null
  //   }).then(products => {
  //     commit('setProducts', products)
  //   })
  // },
  /**
   * Get Stock of a product from Firebase and return
   */
  getStockFB: async (
    {},
    { userId: userId, productId: productId, constraints: constraints }
  ) => {
    const stockDb = new stockDB({
      userId: userId,
      productId: productId
    })
    const stock = await stockDb.readAll({ constraints })
    return stock
  },
  /**
   * Get Specific stock item
   */
  getSpecficStockFB: async (
    {},
    { userId: userId, productId: productId, stockId: stockId }
  ) => {
    const stockDb = new stockDB({
      userId: userId,
      productId: productId
    })
    const stock = await stockDb.read(stockId)
    return stock
  },
  /**
   * Get Stock of a product
   */
  getStock: ({ state, rootState, commit, dispatch }) => {
    dispatch('getStockFB', {
      userId: rootState.authentication.user.id,
      productId: state.productToCreate.id,
      constraints: null
    }).then(stock => {
      
      commit('setStock', stock)
      /**
       * We need to set the headers based on the selected products,
       * We get the first object, and check which values it has, and use that as headers
       */
      const newHeaders = []
      let counter = 0
      if (stock[0]) {
        const firstFromStock = Object.keys(stock[0])
        const arrayLength = firstFromStock.length
        for (let i = 0; i < arrayLength; i++) {
          counter++
          const key = firstFromStock[i]

          let text = key
          switch (key) {
            case 'updateTimestamp':
              text = 'updated'
              break
            case 'createTimestamp':
              text = 'created'
              break
            default:
            // code block
          }

          if (key !== 'productId') {
            const newHeader = { text: text, value: key, sortable: true }
            newHeaders.push(newHeader)
            if (counter === arrayLength) {
              const actionHeader = {
                text: 'Actions',
                value: 'action',
                sortable: false
              }
              newHeaders.push(actionHeader)
              commit('setStockHeader', newHeaders)
              return
            }
          }
        }
      }
    })
  },
  /**
   * Start creating a new product
   */
  startCreatingNewProduct: ({ state, commit, rootState }) => {
    commit('resetProductToCreate', false)

    // default: {
    //   name: {
    //     label: ['naam', 'nom', 'name'],
    //     i18n: true,
    //     value: [''],
    //     type: 'string'
    //   },
    //   descr: {
    //     label: ['Omschrijving', 'description', 'description'],
    //     i18n: true,
    //     value: [''],
    //     type: 'string'
    //   },
    //   org: {
    //     label: ['Organisatie', 'organisation', 'organisation'],
    //     i18n: true,
    //     value: null,
    //     type: 'button'
    //   },
    //   reserve: {
    //     label: ['Aantal fietsen als reserve', 'Aantal fietsen als reserve', 'Aantal fietsen als reserve'],
    //     i18n: false,
    //     value: 0,
    //     type: 'number'
    //   }
    // },

    // setProductToCreate: (
    //   state,
    //   { value: value, fieldName: fieldName, lang: lang }
    // ) => {
    //   const editProduct = state.productToCreate;
    //   if (lang === null) {
    //     editProduct['default'][fieldName].value = value
    //   } else {
    //     const editProduct = state.productToCreate;
    //     editProduct['default'][fieldName].value[lang] = value
    //   }
    //   state.productToCreate = _.cloneDeep(editProduct)
    // },


    
    // Hacky way to get the user's organisa
    const organisation = Object.keys(rootState.authentication.user.organisations)[0]
    commit('setProductToCreate', { value: organisation, fieldName: 'org', lang: null })


    commit('setStock', null)
    commit('setProductFunction', 'create')
  },
  /**
   * create Stock
   */
  createStock: async (
    { state, commit, rootState, dispatch },
    { stockList: stockList, productId: productId }
  ) => {
    if (productId === undefined) return
    const stockDb = new stockDB({
      userId: rootState.authentication.user.id,
      productId: productId
    })
    for (const stockItem of stockList) {
      const data = {
        location: stockItem.location,
        status: 0,
        reference: stockItem.reference,
        productId: productId
      }
      stockDb.create(data)
    }
    // Reset number of stock
    dispatch('getStock')
    commit('setStockListToCreate', [])
    commit('resetStockToCreate')
  },
  addStockToStockToCreateArray: ({ state, commit }, stockToCreate) => {
    const stockList = [...state.stockListToCreate, stockToCreate]
    commit('setStockListToCreate', stockList)
    commit('resetStockToCreate')
  },
  /**
   * Add a product
   */
  addProduct: async ({ dispatch, state, commit, rootState }) => {
    const productDb = new productsDB(rootState.authentication.user.id)
    commit('setProductCreationPending', true)

    const createdProduct = await productDb.create(state.productToCreate)

    dispatch('createStock', {
      stockList: state.stockListToCreate,
      productId: createdProduct.id
    })
    commit('addProduct', createdProduct)
    commit('resetProductToCreate', false)
    commit('setProductFunction', null)
    dispatch('setProductToEdit', { product: createdProduct })
    commit('setProductCreationPending', false)
  },
  /**
   * Save a product
   */
  saveProduct: async ({ state, commit, rootState, dispatch }) => {
    const productDb = new productsDB(rootState.authentication.user.id)
    commit('setProductCreationPending', true)
    const saveProduct = await productDb.update(state.productToCreate)
    dispatch('createStock', {
      stockList: state.stockListToCreate,
      productId: saveProduct
    })
    dispatch('getProductsFB', {
      userId: rootState.authentication.user.id,
      constraints: [
        [
          'default.org.value',
          '==',
          rootState.authentication.userActiveOrganisation.id
        ]
      ]
    }).then(products => {
      commit('setProducts', products)
      commit('setProductCreationPending', false)
    })
  },
  /**
   * Set product to edit
   */
  setProductToEdit: async ({ commit, dispatch }, { product: product }) => {
    commit('resetProductToCreate', false)
    commit('setProductToEdit', product)
    commit('setProductFunction', 'edit')
    dispatch('getStock')
  },
  /**
   * Save a product
   */
  updateStockItem: async (
    { state, commit, rootState, dispatch },
    { productId: productId, stockItem: stockItem, value: value, field: field }
  ) => {
    const item = JSON.parse(JSON.stringify(stockItem))
    item[field] = value
    const stockDb = new stockDB({
      userId: rootState.authentication.user.id,
      productId: productId
    })
    const stock = await stockDb.update(item)
    dispatch('getStockFB', {
      userId: rootState.authentication.user.id,
      productId: productId,
      constraints: null
    }).then(stock => {
      commit('setStock', stock)
    })
  },
  
  // Still needed, or is in this on firebase function level?
  deleteStock: async (
    { state, commit, rootState, dispatch },
    { productId: productId, stockId: stockId }
  ) => {
    const stockDb = new stockDB({
      userId: rootState.authentication.user.id,
      productId: productId
    })
    const stockDeleted = await stockDb.delete(stockId)
    dispatch('getStockFB', {
      userId: rootState.authentication.user.id,
      productId: productId,
      constraints: null
    }).then(stock => {
      commit('setStock', stock)
    })
  },

  /**
   * TODO: What if a product has stock and/or is booked?
   * A) Loop all stock and check if booked
   *  - Get Stock
   *  - Loop Stock for active Booking
   * B) If no booking, delete all
   */
  deleteProduct: async (
    { state, commit, rootState, dispatch },
    { productId: productId }
  ) => {
    const productDb = new productsDB(rootState.authentication.user.id)
    // TODO: add check if this products belongs to the organisation of the user
    const productDeleted = await productDb.delete(productId)
    dispatch('getProductsFB', {
      userId: rootState.authentication.user.id,
      constraints: [
        [
          'default.org.value',
          '==',
          rootState.authentication.userActiveOrganisation.id
        ]
      ]
    }).then(products => {
      commit('setProducts', products)
      commit('resetProductToCreate', false)
      commit('setProductFunction', null)
    })
  }
}
