import { find } from 'lodash'

export default {
  getOrganisationById: (state) => (orgId) => {
    return find(state.organisations, (org) => org.id === orgId)
  },
  getLocationById: (state) => (locId) => {
    const location = find(state.orgLocations, (org) => org.id === locId)
    if (location === undefined) {
      return null
    } else {
      return location
    }
  },
}
