export default {
  user: undefined,
  userLang: 0,
  admin: false,
  userActiveOrganisation: null,
  actionCodeSettings: {
    url: process.env.VUE_APP_URL,
    handleCodeInApp: true
  }
}
