import state from './admin-products.state'
import mutations from './admin-products.mutations'
import actions from './admin-products.actions'
import getters from './admin-products.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
