<template>
  <v-bottom-navigation
    app
    scroll-target="#scroll-area-1"
    hide-on-scroll
    horizontal
  >
  <!-- TODO: Translate -->
    <v-btn v-if="admin" @click="nav({ path: '/admin', editing: null })">
      <span>Admin</span>
      <v-icon>mdi-settings-outline</v-icon>
    </v-btn>

<!-- TODO: Translate -->
    <v-btn @click="nav({ path: '/dashboard', editing: null })">
      <span><copy item="navHome" :lang="userLang"></copy></span>
      <v-icon>mdi-clipboard-list-outline</v-icon>
    </v-btn>
<!-- TODO: Translate -->
    <v-btn @click="nav({ path: '/booking', editing: null })">
      <span><copy item="reservation" :lang="userLang"></copy></span>
      <v-icon>mdi-bike</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import router from '@/router'

export default {
  props: {
    admin: Boolean
  },
  computed: {
    ...mapState('authentication', ['userLang', 'user'])
  },
  methods: {
    ...mapMutations('adminBookings', [
      'setEditingBooking',
      'setSelectedOrgForBooking'
    ]),
    nav({ path: path, editing: editing }) {
      if (router.currentRoute.path === path) return
      if (path === '/booking' && editing === null) {
        this.setEditingBooking(editing)
        this.setSelectedOrgForBooking(editing)
        router.push(path)
      } else {
        router.push(path)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
