import { find, flatMapDepth } from 'lodash'

export default {
  /**
   * Get product by id
   */
  getProductById: state => productId => {
    
    return find(state.allProducts, product => product.id === productId)
  },
  getTotalSelection: state => selectedStock => {
    return flatMapDepth(selectedStock).length
  },
  isSelectedStockEmpty: state => {
    return Object.keys(state.selectedStock).length === 0
  }
}
