<template>
  <v-content>
    <v-container fill-height>
      <v-row justify="center" align="center">
        <v-col justify="center" align="center" cols="1" sm="3">
          <app-logo
            class="animated pulse logoLoading"
            color="#ee2a7b"
          ></app-logo>
        </v-col>
      </v-row>
    </v-container>
  </v-content>
</template>

<script>
import { mapState } from 'vuex'
import { isNil } from 'lodash'
import AppLogo from '@/components/App-Logo'

export default {
  components: { AppLogo },
  computed: mapState('authentication', ['user']),
  watch: {
    user: {
      handler(user) {
        if (user === undefined) return
        if (this.$route.query.redirectUrl === this.$route.path) {
          this.$router.push('/')
        }

        const redirectUrl = isNil(user)
          ? `/login?redirectUrl=${this.$route.query.redirectUrl}`
          : this.$route.query.redirectUrl

        this.$router.push(redirectUrl)
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.logoLoading {
  animation-duration: 2s;
  opacity: 0.2;
  animation-iteration-count: infinite;
}
</style>
