import Vue from 'vue'
import Router from 'vue-router'
import Head from 'vue-head'
import { isNil } from 'lodash'
import firebase from 'firebase/app'
import Client from '@/views/Client'
import CheckLogin from '@/views/CheckLogin'
import store from '@/store'

Vue.use(Router)
/* If you don't know about VueHead, please refer to https://github.com/ktquez/vue-head */
Vue.use(Head, {
  complement: process.env.VUE_APP_TITLE
})
/* If you don't know about VueRouter, please refer to https://router.vuejs.org/ */
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Client,
      redirect: '/dashboard',
      meta: {
        authNotRequired: true
      },
      children: [
        {
          path: 'guide',
          component: () =>
            import(/* webpackChunkName: "client-chunk-products" */ '@/views/Client-Guide.vue')
        },
        {
          path: 'dashboard',
          component: () =>
            import(/* webpackChunkName: "client-chunk-products" */ '@/views/Client-Dashboard.vue')
        },
        {
          path: 'booking',
          component: () =>
            import(/* webpackChunkName: "client-chunk-products" */ '@/views/Client-Booking.vue')
        }
      ]
    },
    {
      path: '/check-login',
      name: 'check-login',
      component: CheckLogin,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/__/',
      name: 'magicLinkEndPoint',
      component: Client,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () =>
        import(/* webpackChunkName: "client-chunk-login" */ '@/views/Login.vue'),
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/admin',
      name: 'Admin',
      component: () =>
        import(/* webpackChunkName: "client-chunk-products" */ '@/views/Admin.vue'),
      children: [
        {
          path: 'products',
          component: () =>
            import(/* webpackChunkName: "client-chunk-products" */ '@/views/Admin-Products.vue')
        },
        {
          path: 'organisations',
          component: () =>
            import(/* webpackChunkName: "client-chunk-products" */ '@/views/Admin-Organisations.vue')
        },
        {
          path: 'users',
          component: () =>
            import(/* webpackChunkName: "client-chunk-products" */ '@/views/Admin-Users.vue')
        },
        {
          path: 'translate',
          component: () =>
            import(/* webpackChunkName: "client-chunk-products" */ '@/views/Admin-Translate.vue')
        },
        {
          path: 'bookings',
          component: () =>
            import(/* webpackChunkName: "client-chunk-products" */ '@/views/Admin-Bookings.vue')
        }
      ]
    },
    { path: '*', redirect: '/' }
  ]
})
/**
 * Handle user redirections
 */
router.beforeEach((to, from, next) => {
  if (
    !(to.meta && to.meta.authNotRequired) &&
    isNil(store.state.authentication.user)
  ) {
    const windowLocation = window.location.href
    if (firebase.auth().isSignInWithEmailLink(windowLocation)) {
      let email = window.localStorage.getItem('emailForSignIn') 
      if (!email) {
        email = window.prompt('Voer je email adres in als bevestiging / Pouvez-vous confirmer votre adresse Email')
      }
      
      firebase
        .auth()
        .signInWithEmailLink(email, windowLocation)
        .then(function(result) {
          // Clear email from storage.
          window.localStorage.removeItem('emailForSignIn')
        })
        .catch(function(error) {
          console.log(error)
        })
    } else {
    }
    const path =
      store.state.authentication.user === null ? '/login' : '/check-login'
    return next(`${path}?redirectUrl=${to.path}`)
  }
  next()
})
export default router
