<template>
  <v-navigation-drawer v-model="toggleDrawer" app>
    <v-list dense>
      <v-list-item @click="nav('/admin/products')">
        <v-list-item-action>
          <v-icon>mdi-bicycle</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            <copy item="navProducts" :lang="userLang"></copy>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list dense>
      <v-list-item @click="nav('/admin/organisations')">
        <v-list-item-action>
          <v-icon>mdi-factory</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            <copy item="navOrganisations" :lang="userLang"></copy
          ></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list dense>
      <v-list-item @click="nav('/admin/users')">
        <v-list-item-action>
          <v-icon>mdi-account-group</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title
            ><copy item="navUsers" :lang="userLang"></copy>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list dense>
      <v-list-item @click="nav('/admin/translate')">
        <v-list-item-action>
          <v-icon>mdi-translate</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            <copy item="navTranslate" :lang="userLang"></copy>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list dense>
      <v-list-item @click="nav('/admin/bookings')">
        <v-list-item-action>
          <v-icon>mdi-calendar-clock</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            <copy item="navBookings" :lang="userLang"></copy>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'
import router from '@/router'

export default {
  computed: {
    ...mapGetters('authentication', ['isUserLoggedIn']),
    ...mapState('authentication', ['user', 'userLang']),
    ...mapState('app', [
      'networkOnLine',
      'appTitle',
      'appShortTitle',
      'drawer'
    ]),
    ...mapState('translations', ['languages', 'translations']),
    toggleDrawer: {
      set() {},
      get() {
        return this.drawer
      }
    }
  },
  methods: {
    ...mapMutations('app', ['setDrawer']),
    nav(path) {
      router.push(path)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
