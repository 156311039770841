<template>
  <v-container fluid>
    <v-row v-if="active" style="justify-content:center" >
        <v-col md="6" sm="8" xs="10" style="align-items:center">
          <v-alert :type="type">
            <!-- <copy item="loginMailIsSend" :lang="0"></copy> -->
            {{ message }}
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
// import AdminProductsSpecAddDialog from '@/components/Admin-ProductsSpecAddDialog'

export default {
  // components: { AdminProductsSpecAddDialog },
//   props: {
//     inline: Boolean
//   },
  computed: {
    ...mapState('notifications', ['active', 'type', 'message'])
  },
//   data() {
//     return {
//       theme: {
//         container: {
//           // INFO: https://github.com/nathanreyes/v-calendar/blob/next/src/utils/defaults/theme.js
//           light: 'light-container-class',
//           dark: 'dark-container-class'
//         },
//         bgAccentLow: {
//           light: 'dt-range__selected'
//         },
//         bgAccentHigh: {
//           light: 'dt__range__beginEnd',
//           dark: ''
//         }
//       }
//     }
//   },
//   methods: {
//     ...mapActions('adminBookings', ['checkDateForBooking'])
//   }
}
</script>

<style lang="scss">
  @import '@/theme/variables.scss';
  @import '@/theme/admin.scss';
</style>