import { isNil } from 'lodash'
import firebase from 'firebase/app'
import router from '@/router'
import { createNewUserFromFirebaseAuthUser } from '@/misc/helpers'
import UsersDB from '@/firebase/users-db'
import StockFN from '@/firebase/stock-fn'


export default {
  changeUserActiveOrganisation: (
    { commit, dispatch, state },
    { id: id, role: role }
  ) => {
    if (id === undefined) return
    commit('setUserActiveOrganisation', {
      id: id,
      role: role
    })

    // Get all the locations for this organisation
     dispatch(
      'adminOrganisations/getLocations',
      {
        organisationId: state.userActiveOrganisation.id
      },
      { root: true }
    )
    // Get all the products for this organisation
    dispatch(
      'adminProducts/getProductsFB',
      {
        userId: state.user.id,
        constraints: [
          ['default.org.value', '==', state.userActiveOrganisation.id]
        ]
      },
      { root: true }
    ).then(products => {
      commit('adminProducts/setProducts', products, { root: true })
    })

        /*
      Set the dateMode (range or null) for this organisation
    */
    dispatch('adminBookings/setDateMode', id, {
      root: true
    })


  },
  sendMagicLink: async ({ state, dispatch, commit }, { email: email }) => {
    dispatch('notifications/loading', true, {root: true})
    const user = dispatch('findUser', {email: email})
    .then(async userFromFirebase => {
      if(!isNil(userFromFirebase)) {
        let subject;
        let intro;
        let outro;
        let sendTo = userFromFirebase.email.value;
        let replyTo = 'booking@velotheek.be';
        if(userFromFirebase.lang.value.locale === 'nl') {
          subject = "Meld je aan op velotheek.be"
          intro = "Hieronder vind je de link om in te loggen op de Velotheek! Je kan er gewoon op klikken om je aan  te melden. Indien je niet hebt opgevraagd om in te loggen, kan je deze mail negeren."
          outro = `
Veel plezier,

De Velotheek`

        } else {
          subject = "Accès à la Vélotheek"
          intro = "Ci-dessous, vous trouverez le lien d'accès à la plateforme de la Vélotheek:"
          outro = `
Bon Amusement,

Team Vélotheek
`
        }
        // Send body + email + actionCodeSettings + reply email + subject
        const data = {
          intro: intro,
          outro: outro,
          sendTo: sendTo,
          replyTo: replyTo,
          actionCodeSettings: state.actionCodeSettings,
          subject: subject
        }
        const getMagicLinkFN = new StockFN({
          fn: 'sendAuthMail'
        })

        const constraints = null;
        const magicLink = await getMagicLinkFN.callFunction(data, constraints)
        dispatch('notifications/loading', false, {root: true})
        dispatch('notifications/setNotification', {type: 'success', msg: 'loginMailSend', action: '', lang: userFromFirebase.lang.value.id}, {root: true})

        // Place email in localStorage so we can use it when clicking on the magic link
        window.localStorage.setItem('emailForSignIn', email);


      } else {
        dispatch('notifications/loading', false, {root: true})
        dispatch('notifications/setNotification', {type: 'error', msg: 'De Velotheek kon dit email adres niet terugvinden / La Vélotheek ne reconnaît pas votre adresse Email.', action: '', lang: null }, {root: true})

      }
      
    })
    
    
    
    


  },
  /**
   * Callback fired when user login
   */
  login: async ({ commit, dispatch }, firebaseAuthUser) => {
    const constraints = [['email.value', '==', firebaseAuthUser.email]]
    const userFromFirebase = await new UsersDB().readAll({ constraints })
    const user = isNil(userFromFirebase[0])
      ? await createNewUserFromFirebaseAuthUser(firebaseAuthUser)
      : userFromFirebase[0]
    // Added superAdmin = true in the firebase console, there is no interface for this
    if (user.superAdmin) commit('setAdmin', user.superAdmin)
    commit('setUser', user)
    // I'm getting just the first organisation of the organisation list of the user here (bit hacky)
    // TODO: save the active organisation as default organisation for this user, so it's less random
    // console.log(user.organisations)
    for (const [key, value] of Object.entries(user.organisations)) {
      dispatch('changeUserActiveOrganisation', { id: key, role: value })
      if (value >= 50) {
        commit('setAdmin', true)
      }
      break
    }
    
    
    
    commit('setUserLang', user.lang.value.id)
    dispatch('adminUsers/setMomentLanguage', user.lang.value.locale, {
      root: true
    })
    dispatch('adminOrganisations/getOrganisations', {}, { root: true })
  },
  /**
   * Callback fired when user logout
   */
  logout: ({ commit }) => {
    commit('setUser', null)
    commit('adminProducts/setProducts', null, { root: true })
    const currentRouter = router.app.$route
    if (!(currentRouter.meta && currentRouter.meta.authNotRequired)) {
      router.push('/login')
    }
  },
  findUser: async ({ commit, dispatch }, { email: email }) => {
    const emailNoCaps = email.toLowerCase();
    const constraints = [['email.value', '==', emailNoCaps]]
    const userFromFirebase = await new UsersDB().readAll({ constraints })
    return userFromFirebase[0];
  }
}
