import { find } from 'lodash'

export default {
  /**
   * Check if a product has deletion pending
   */
  isProductDeletionPending: state => productId =>
    state.productDeletionPending.includes(productId),

  /**
   * Get product by id
   */
  getProductById: state => productId => {
    if(state.products.length !== null) {
      return find(state.products, product => product.id === productId)
    } else {
      return false
    }
  },
    

  getStockStatus: state => statusId => find(state.stockStatus, status => status.id === statusId)
    
}
